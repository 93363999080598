import { useState, useEffect } from 'react';
import { Grid, Autocomplete, InputAdornment, FormHelperText } from '@mui/material'
import placeholder from 'assets/placeholder.png'
import { Formik, Form, useFormik } from 'formik';
import * as S from './style'
import * as Yup from "yup";
import { RiCake2Fill, RiCameraFill } from "react-icons/ri"
import { useFilePicker } from 'react-sage';
import showToast from 'components/Toast/Toast';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';
import VuiAvatar from "components/VuiAvatar";
import CloseIcon from '@mui/icons-material/Close';
import flatpickr from "flatpickr";
import "assets/theme/custom-flatpickr.css";
import { flatpickrOptionsInfluencer } from 'utils/utils';
import { editUser } from 'utils/requests/auth';
import moment from 'moment';
import 'moment/locale/pt-br';
import { getInfluencerTags } from 'utils/requests/auth';

const Avatar = ({ img, theme, children, ...rest }) => (
  <VuiAvatar
    src={img}
    size="xxl"
    {...rest}
    sx={({ borders: { borderWidth }, palette: { light }, functions: { rgba } }) => ({
      position: "relative",
    })}
  >
    {children}
  </VuiAvatar>
)

const EditUser = ({ userInfo, refetch, closeModal }) => {
  moment.locale("pt-br");

  const [postAvatar, setPostAvatar] = useState();
  const [previewUrl, setPreviewUrl] = useState();
  const [validateFields, setValidateFields] = useState(false);
  const [triggerFlatpickr, setTriggerFlatpickr] = useState(false);
  
  useEffect(() => {
    setTriggerFlatpickr(true);
  }, []);

  const imageInput = useFilePicker({ maxFileSize: 1, resizeImage: true });

  const userTypes = [
    {
      name: 'Administrador',
      id: 'ADMINISTRATOR'
    },
    {
      name: 'Comercial',
      id: 'COMERCIAL'
    },
    {
      name: 'Head Comercial',
      id: 'H_COMERCIAL'
    },
    {
      name: 'Marketing',
      id: 'MARKETING'
    },
    {
      name: 'Head de Marketing',
      id: 'H_MARKETING'
    },
  ];

  useEffect(() => {
    const getDataUrls = async () => {
      if (imageInput.files && imageInput.files[0]) {
        setPostAvatar(imageInput.files[0]);
        const reader = new FileReader();
        reader.readAsDataURL(imageInput.files[0]);
        reader.onload = () => {
          setPreviewUrl(reader.result);
        };
      };
    };
    getDataUrls();
  }, [imageInput.files]);

  const unixDate = (date) => {
    return Number(moment(date, 'DD/MM/YYYY').valueOf());
  };

  const formik = useFormik({
    validateOnChange: validateFields,
    validateOnBlur: false,

    initialValues: {
      name: userInfo?.name ?? '',
      username: userInfo?.username ?? '',
      birthDate: userInfo?.birthDate ? moment(userInfo?.birthDate).format('DD/MM/YYYY') : '',
      bio: userInfo?.bio ?? '',
      type: userInfo?.type ? userTypes.find(type => type.id === userInfo?.type) : null,
    },
    validationSchema: Yup.object({
      name: Yup.string().min(4).required("Nome é um campo obrigatório"),
      username: Yup.string().min(4).required("Username é um campo obrigatório"),
      birthDate: Yup.string(),
      bio: Yup.string(),
      type: Yup.object().required("Tipo é obrigatório"),
    }),
    onSubmit: async (values) => {
      const startUserValues = formik.initialValues;

      const editUserValues = {
        name: values.name !== startUserValues.name ? values.name : '',
        username: values.username !== startUserValues.username ? values.username : '',
        birthDateMs: values.birthDate !== startUserValues.birthDate ? unixDate(values.birthDate) : '',
        bio: values.bio !== startUserValues.bio ? values.bio : '',
        avatar: postAvatar ?? '',
        type: values?.type?.id !== startUserValues?.type?.id ? values.type : '',
      };

      Object.keys(editUserValues).forEach(key => {
        if (editUserValues[key] === '') {
          delete editUserValues[key];
        };
      });

      Object.keys(editUserValues).forEach(key => {
        if ((Array.isArray(editUserValues[key])) && (editUserValues[key].length === 0)) {
          delete editUserValues[key];
        };
      });

      if (Object.keys(editUserValues).length > 0) {
        await editUser(userInfo?.username, editUserValues).then((res) => {
          if (res?.message) {
            showToast(res.message, 'error');
          };
          if (!res?.message) {
            showToast('Usuário criado com sucesso!', 'success');
            refetch();
            closeModal();
          };
        });
      }
      if (Object.keys(editUserValues).length === 0) showToast('Sem dados alterados, campos sem alterações', 'warning');
    }
  });

  flatpickr("#birthDate", flatpickrOptionsInfluencer('birthDate', formik.setFieldValue, formik.setFieldTouched));

  const getPhoto = () => {
    if (!userInfo?.avatar) {
      return placeholder;
    };
    if (userInfo?.avatar && !postAvatar) {
      return userInfo?.avatar;
    };
    if (previewUrl) {
      return previewUrl;
    };
  };

  return (
    <Grid container flexDirection='column'>
      <p>Editar Perfil</p>
      <Grid container justifyContent='center'>
        <S.InfluencerPhoto>
          <div>
            <Avatar img={getPhoto()} />
          </div>
          <S.IndicatorGrid onClick={imageInput.onClick}>
            <S.BranchIndicator>
              <RiCameraFill size='20px' color='#fff' style={{ alignSelf: 'center' }} />
            </S.BranchIndicator>
            <imageInput.HiddenFileInput accept=".jpg, .jpeg, .png" />
          </S.IndicatorGrid>
        </S.InfluencerPhoto>
      </Grid>
      <Formik initialValues={formik.initialValues} validationSchema={formik.validationSchema} onSubmit={formik.handleSubmit}>
        <Form>
          <Grid container flexDirection='column' gap='20px' mt='15px'>
            <Grid container justifyContent='space-between'>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  hiddenLabel
                  name='name'
                  placeholder="Nome"
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name || ""}
                  error={formik.errors.name}
                  helperText={formik.errors.name ? 'Nome inválido' : false}
                />
              </S.InputGrid>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  hiddenLabel
                  name='username'
                  placeholder="Username"
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.username || ""}
                  error={formik.errors.username}
                  helperText={formik.errors.username ? 'Username inválido' : false}
                />
              </S.InputGrid>
            </Grid>
            <Grid container justifyContent='space-between'>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <Autocomplete
                  id='type'
                  disablePortal
                  disableClearable
                  readOnly
                  value={formik.values?.type ? formik.values?.type : null}
                  onChange={(e, value) => {
                    formik.setFieldValue('type', value)
                  }}
                  options={userTypes}
                  sx={{ width: "100%" }}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => <S.Input {...params} error={formik.errors.type} placeholder='Tipo' />}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                />
                {(Boolean(formik.errors.type)) &&
                  <FormHelperText sx={{ color: "#f44336", marginLeft: "14px" }}>
                    Tipo é um campo obrigatório
                  </FormHelperText>
                }
              </S.InputGrid>
              <S.InputGrid item xs={12} md={5.85} xl={5.85} id='birthDate'>
                <S.Input
                  name='birthDate'
                  hiddenLabel
                  type='text'
                  placeholder="Data de Nascimento"
                  fullWidth
                  data-input
                  autoComplete='off'
                  value={formik.values.birthDate}
                  error={Boolean(formik.errors.birthDate)}
                  helperText={formik.errors.birthDate}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <RiCake2Fill />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position='end'>
                        <CloseIcon onClick={() => formik.setFieldValue('birthDate', '')} sx={{ cursor: "pointer", "&:hover": { color: "#E22B00" } }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </S.InputGrid>
            </Grid>
            <Grid container gap='20px' justifyContent='space-between'>
              <S.InputGrid item xs={12} xl={12}>
                <S.Input
                  hiddenLabel
                  name='bio'
                  placeholder="Descrição"
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.bio || ""}
                />
              </S.InputGrid>
            </Grid>
          </Grid>
          <Grid container justifyContent='flex-end' mt='15px'>
            <ButtonCustom
              label='Salvar'
              type='submit'
              sx={{ width: "150px", height: "40px !important", fontSize: "18px", fontWeight: "500" }}
              onClick={() => setValidateFields(true)}
            />
          </Grid>
        </Form>
      </Formik>
    </Grid>
  )
}

export default EditUser