let REACT_APP_API_URL = process.env.REACT_APP_API_URL;
if (!process.env.REACT_APP_API_URL) REACT_APP_API_URL = process.env.REACT_APP_API_DEV_URL

export const getClients = async (page, filters) => {
  const token = localStorage.getItem("token");
  const branchesQuery = filters?.branches?.length > 0 ? `&${filters.branches.map(type => `branch[]=${type}`).join('&')}` : '';
  const curvesQuery = filters?.curves?.length > 0 ? `&${filters.curves.map(type => `curve[]=${type}`).join('&')}` : '';
  const response = await fetch(REACT_APP_API_URL + `/dashboard/leads${page >= 0 ? `?page=${page}&size=10` : ''}${branchesQuery}${curvesQuery}${filters?.businessName ? `&businessName=${filters?.businessName}` : ''}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
}

export const getAllClients = async () => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/leads?returnAll=true`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
}

export const getLead = async (username) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/leads/${username}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
}

export const createLead = async (leadInfo) => {
  const formData = new FormData();
  Object.entries(leadInfo).forEach(entry => {
    const isArray = Array.isArray(entry[1]);
    if (isArray) {
      entry[1].forEach((str, index) => {
        formData.append(`${entry[0]}[${index}]`, str);
      })
      return
    }
    formData.append(entry[0], entry[1]);
  });
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/leads`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`
    },
    body: formData,
  });
  const data = await response.json();
  return data;
}

export const createContact = async (contactInfo) => {
  const formData = new FormData();
  Object.entries(contactInfo).forEach(entry => {
    const isArray = Array.isArray(entry[1]);
    if (isArray) {
      entry[1].forEach((str, index) => {
        formData.append(`${entry[0]}[${index}]`, str);
      })
      return
    }
    formData.append(entry[0], entry[1]);
  });
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/leads/contact`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`
    },
    body: formData,
  });
  const data = await response.json();
  return data;
}

export const editLead = async (id, leadInfo) => {
  const formData = new FormData();
  Object.entries(leadInfo).forEach(entry => {
    const isArray = Array.isArray(entry[1]);
    if (isArray) {
      entry[1].forEach((str, index) => {
        formData.append(`${entry[0]}[${index}]`, str);
      })
      return
    }
    formData.append(entry[0], entry[1]);
  });
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/leads/${id}`, {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${token}`
    },
    body: formData,
  });
  const data = await response.json();
  return data;
}

export const deleteLead = async (id) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/leads/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};

export const getCommercialHomeGraphs = async (startDate, endDate) => {
  const token = localStorage.getItem("token");
  const startDateQuery = startDate ? `?startDateMs=${startDate}` : '';
  const endDateQuery = endDate ? `&endDateMs=${endDate}` : '';
  const response = await fetch(REACT_APP_API_URL + `/dashboard/leads/home${startDateQuery}${endDateQuery}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};

export const editContact = async (id, leadInfo) => {
  const formData = new FormData();
  Object.entries(leadInfo).forEach(entry => {
    const isArray = Array.isArray(entry[1]);
    if (isArray) {
      entry[1].forEach((str, index) => {
        formData.append(`${entry[0]}[${index}]`, str);
      })
      return
    }
    formData.append(entry[0], entry[1]);
  });
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/leads/contact/${id}`, {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${token}`
    },
    body: formData,
  });
  const data = await response.json();
  return data;
}

export const deleteContact = async (id) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/leads/contact/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};

export const createSimpleLead = async (project) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/leads`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(project),
  });
  const data = await response.json();
  return data;
};