import React from 'react'
import DashboardLayout from "defaultComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "defaultComponents/Navbars/DashboardNavbar";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiPagination from "components/VuiPagination";
import Table from "defaultComponents/Tables/Table";
import { Grid, Box } from '@mui/material';
import * as S from './style'
import instagram from '../../../assets/icons/socials/instagram.png'
import youtube from '../../../assets/icons/socials/youtube.png'
import tiktok from '../../../assets/icons/socials/tiktok.png'
import twitter from '../../../assets/icons/socials/twitter.png'
import spotify from '../../../assets/icons/socials/spotify.png'
import { useNavigate, Link } from 'react-router-dom';
import { IoArrowBack, IoArrowForward, IoEllipsisVertical } from 'react-icons/io5';
import { getClients, deleteLead } from 'utils/requests/leads';
import filter from 'assets/icons/filter.svg'
import FilterModal from 'components/Modal/FilterModal';
import { SearchBarContext } from 'context/SearchBarContext';
import { useDebounce } from "use-debounce";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { RiAddFill } from "react-icons/ri";
import CreateClients from './ModalContent/CreateClients';
import Modal from 'components/Modal/Modal';
import * as T from "components/Toast/style"
import showToast from 'components/Toast/Toast';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';
import { TokenContext } from 'context/TokenContext';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import { branches, curves } from 'utils/utils';
import VuiAvatar from 'components/VuiAvatar';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#e0e0e0',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}));

const Avatar = ({ img, theme, children, ...rest }) => (
  <VuiAvatar
    src={img}
    size="md"
    {...rest}
    sx={({ borders: { borderWidth }, palette: { light }, functions: { rgba } }) => ({
      borderRadius: '15px',
      position: "relative",
    })}
  >
    {children}
  </VuiAvatar>
)

const Clients = () => {
  const navigate = useNavigate();

  const { decodedToken } = React.useContext(TokenContext);
  const role = decodedToken?.type;

  const [leads, setLeads] = React.useState([]);
  const [pagination, setPagination] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [openFilter, setOpenFilter] = React.useState(false);
  const [branchFilterValues, setBranchFilterValues] = React.useState([]);
  const [curveFilterValues, setCurveFilterValues] = React.useState([]);
  const [filters, setFilters] = React.useState({ branches: [], curves: [], businessName: null });
  const [openCreateClientModal, setOpenCreateClientModal] = React.useState(false);
  const [openMenu, setOpenMenu] = React.useState([]);
  const [openConfirmDeletionModal, setOpenConfirmDeletionModal] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState();

  const { searchQuery } = React.useContext(SearchBarContext);

  const [debouncedSearch] = useDebounce(searchQuery, 600);
  const [debouncedFilters] = useDebounce(filters, 600);

  const calculatePagination = () => {
    const roundUpArrSize = Math.ceil(leads[1] / 10);
    const paginationArr = Array.from({ length: roundUpArrSize }, (value, index) => index)
    setPagination(paginationArr);
  };

  const fetchClients = async () => {
    const data = await getClients(page, debouncedFilters);
    if (!data.message) {
      setLeads(data);
    };
  };

  React.useEffect(() => {
    const branchCheckArr = Array.from({ length: branches.length }, () => false);
    setBranchFilterValues(branchCheckArr)
    const curveCheckArr = Array.from({ length: curves.length }, () => false);
    setCurveFilterValues(curveCheckArr)
  }, []);

  React.useEffect(() => {
    fetchClients();
  }, [page, debouncedFilters]);

  React.useEffect(() => {
    calculatePagination();
  }, [leads]);

  React.useEffect(() => {
    setFilters((prevData) => ({ ...prevData, businessName: debouncedSearch ? debouncedSearch.toUpperCase() : null }));
    setPage(0);
  }, [debouncedSearch]);

  const handleBranchOnChange = (position) => {
    const updatedCheckedState = branchFilterValues.map((item, index) =>
      index === position ? !item : item
    );
    setBranchFilterValues(updatedCheckedState);
  };

  const handleCurveOnChange = (position) => {
    const updatedCheckedState = curveFilterValues.map((item, index) =>
      index === position ? !item : item
    );
    setCurveFilterValues(updatedCheckedState);
  };

  const branchesFilter = () => {
    const checkedIndexes = branchFilterValues?.map((bool, index) => bool === true ? index : null).filter((el) => (el !== null));
    const result = checkedIndexes?.map((index) => branches[index]);
    result.length !== filters.branches.length ? setFilters((prevData) => ({ ...prevData, branches: result })) : null;
    setPage(0);
  };

  React.useEffect(() => {
    branchesFilter();
  }, [branchFilterValues]);

  const curvesFilter = () => {
    const checkedIndexes = curveFilterValues?.map((bool, index) => bool === true ? index : null).filter((el) => (el !== null));
    const result = checkedIndexes?.map((index) => curves[index]);
    result.length !== filters.curves.length ? setFilters((prevData) => ({ ...prevData, curves: result })) : null;
    setPage(0);
  };

  React.useEffect(() => {
    curvesFilter();
  }, [curveFilterValues]);

  const toggleOpenMenu = (index) => {
    const updatedMenu = [...openMenu];
    updatedMenu[index] = !updatedMenu[index];
    setOpenMenu(updatedMenu);
  };

  const handleDeleteModal = (id) => {
    setOpenConfirmDeletionModal(true);
    setDeleteId(id);
  };

  const cancelDelete = () => {
    setOpenConfirmDeletionModal(false);
    setDeleteId('');
  };

  const handleDelete = async () => {
    await deleteLead(deleteId).then((res) => {
      if (res?.businessName) {
        fetchClients();
        showToast("Cliente deletado com sucesso", "success");
        setOpenConfirmDeletionModal(false);
        return
      }
      showToast("Falha ao apagar cliente", "error");
    })
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <DashboardLayout onClick={() => openMenu.includes(true) ? setOpenMenu(openMenu.map((bool) => false)) : null}>
      <DashboardNavbar />
      <T.ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <VuiBox py={1}>
        <VuiBox mb={1}>
          <S.TableCard>
            <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="22px">
              <VuiTypography variant="lg" color="white">
                Todos os Clientes
              </VuiTypography>
              <S.EditButton onClick={() => setOpenFilter(true)}>
                <img src={filter} draggable='false' />
              </S.EditButton>
            </VuiBox>
            <VuiBox>
              <Table
                columns={[
                  { name: "nome da empresa", align: "left" },
                  { name: "ramo", align: "left" },
                  { name: "curva", align: "center" },
                  { name: "projetos", align: "center" },
                  { name: "redes", align: "center" },
                ]}
                rows={
                  leads[0] && leads[0].map((lead, index) => {
                    /* leads.map((lead) => { */
                    return {
                      "nome da empresa": (
                        <S.InfluencerGrid
                          container
                          flexDirection='row'
                          onClick={() => navigate(`/comercial/clientes/${lead.id ? lead.id : ""}`)}
                          key={lead?.id}
                          data-cy={lead.businessName}
                        >
                          <Grid container item xl={lead.businessDescription ? 1.75 : 2.75} lg={3} md={3} >
                            <S.RankingPhoto>
                              <Avatar src={lead.avatar ? lead.avatar : ''} />
                            </S.RankingPhoto>
                          </Grid>
                          <S.UserTextGrid container flexDirection='column' item xl={8.5} lg={7} md={8}>
                            <p className='name'>{lead.businessName}</p>
                            <p>
                              {
                                lead?.businessDescription?.length > 60
                                  ?
                                  lead.businessDescription.substring(0, 60) + "..."
                                  :
                                  lead.businessDescription
                              }
                            </p>
                          </S.UserTextGrid>
                        </S.InfluencerGrid>
                      ),
                      ramo: (
                        <S.ThemeGrid container flexDirection='column'>
                          <p className='name'>{lead?.branch}</p>
                          {/* <p className='tags'>{lead.content_themes && lead.content_themes.join(", ")}</p> */}
                        </S.ThemeGrid>
                      ),
                      curva: (
                        <S.ReachGrid container justifyContent='center'>
                          <p className="count">{lead?.curve}</p>
                        </S.ReachGrid>
                      ),
                      projetos: (
                        <S.StatusGrid container justifyContent='center'>
                          {/* <p className='status'>{lead?.active ? "ACTIVE" : "INACTIVE"}</p> */}
                        </S.StatusGrid>
                      ),
                      redes: (
                        <Grid container justifyContent='space-between'>
                          <S.SocialsGrid
                            container
                            item
                            xs={role === "ADMINISTRATOR" ? 9 : 12}
                            md={role === "ADMINISTRATOR" ? 11 : 12}
                            xl={role === "ADMINISTRATOR" ? 11 : 12}
                            justifyContent='center'
                          >
                            {lead?.instagram?.username
                              ?
                              <Link to={`https://www.instagram.com/${lead?.instagram?.username}`} target="_blank">
                                <img src={instagram} height='18px' draggable='false' />
                              </Link>
                              :
                              <img src={instagram} height='18px' draggable='false' className='disabled' />
                            }
                            {lead?.youtube?.username
                              ?
                              <Link to={`https://www.youtube.com/@${lead?.youtube?.username}`} target="_blank">
                                <img src={youtube} height='18px' draggable='false' />
                              </Link>
                              :
                              <img src={youtube} height='18px' draggable='false' className='disabled' />
                            }
                            {lead?.tiktok?.username
                              ?
                              <Link to={`https://www.tiktok.com/@${lead?.tiktok?.username}`} target="_blank" className={lead?.instagram?.username ? 'active' : 'disabled'}>
                                <img src={tiktok} height='18px' draggable='false' />
                              </Link>
                              :
                              <img src={tiktok} height='18px' draggable='false' className='disabled' />
                            }
                            {lead?.twitter?.username
                              ?
                              <Link to={`https://www.twitter.com/${lead?.twitter?.username}`} target="_blank" className={lead?.instagram?.username ? 'active' : 'disabled'}>
                                <img src={twitter} height='18px' draggable='false' />
                              </Link>
                              :
                              <img src={twitter} height='18px' draggable='false' className='disabled' />
                            }
                            <img src={spotify} height='18px' draggable='false' style={{ filter: "grayscale(1)" }} />
                          </S.SocialsGrid>
                          {role === "ADMINISTRATOR" &&
                            <Grid item xs={3} md={1} xl={1} sx={{ alignSelf: "center" }} onClick={() => toggleOpenMenu(index)} data-cy="open-menu">
                              <IoEllipsisVertical size='20px' style={{ cursor: "pointer" }} />
                            </Grid>
                          }
                          {openMenu[index] &&
                            <S.OptionsContainer container justifyContent='flex-end'>
                              <S.OptionsGrid display='flex' flexDirection='column'>
                                <p onClick={() => handleDeleteModal(lead?.id)} data-cy="remove-company">Excluir</p>
                              </S.OptionsGrid>
                            </S.OptionsContainer>
                          }
                        </Grid>
                      ),
                    }
                  },)
                }
              />
            </VuiBox>
          </S.TableCard>
        </VuiBox>
      </VuiBox>
      <Grid container justifyContent='center'>
        <VuiPagination color='viuzzPag'>
          <VuiPagination item disabled={pagination.length <= 1} onClick={() => page > 0 ? setPage(page => page - 1) : null}>
            <IoArrowBack />
          </VuiPagination>
          {pagination.length > 1 && pagination.map((number) => (
            <VuiPagination item active={number === page} key={number} onClick={() => setPage(number)}>{number + 1}</VuiPagination>
          ))
          }
          {pagination.length <= 1 &&
            <VuiPagination item active>1</VuiPagination>
          }
          <VuiPagination item disabled={pagination.length <= 1} onClick={() => (page + 1) < pagination.length ? setPage(page => page + 1) : null}>
            <IoArrowForward />
          </VuiPagination>
        </VuiPagination>
      </Grid>
      {(role === "ADMINISTRATOR" || role === "H_COMERCIAL" || role === "COMERCIAL") &&
        <Box sx={{ '& > :not(style)': { m: 1 } }}>
          <S.ModalFab color="primary" onClick={() => setOpenCreateClientModal(true)}>
            <RiAddFill size='30px' />
          </S.ModalFab>
        </Box >
      }
      <FilterModal size='sm' open={openFilter} onClose={() => setOpenFilter(false)}>
        <p>Filtragem por ramo</p>
        {branches.map((branch, index) => (
          <Grid container key={index}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={branchFilterValues[index]}
              onChange={() => handleBranchOnChange(index)}
              name={branch}
            />
            <p style={{ fontSize: "18px" }}>{branch}</p>
          </Grid>
        ))
        }
        <p style={{ marginTop: '15px' }}>Filtragem por curva</p>
        {curves.map((curve, index) => (
          <Grid container key={index}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={curveFilterValues[index]}
              onChange={() => handleCurveOnChange(index)}
              name={curve}
            />
            <p style={{ fontSize: "18px" }}>{curve}</p>
          </Grid>
        ))
        }
      </FilterModal>
      <Modal open={openCreateClientModal} onClose={() => setOpenCreateClientModal(false)}>
        <CreateClients refetch={fetchClients} closeModal={() => setOpenCreateClientModal(false)} />
      </Modal>
      <Modal size='sm' open={openConfirmDeletionModal} onClose={() => cancelDelete()}>
        <Grid container flexDirection='column' gap='20px'>
          <p style={{ fontSize: "24px" }}>Confirmar exclusão</p>
          <p style={{ fontSize: "18px" }}>Deseja excluir essa entrada? Essa ação é irreversível</p>
          <Grid container gap='15px' justifyContent='center'>
            <Grid item lg={3}>
              <ButtonCustom label='Cancelar' onClick={() => cancelDelete()} sx={{ background: "linear-gradient(180deg, #818181 0%, #2a2020 155.15%)" }}></ButtonCustom>
            </Grid>
            <Grid item lg={3}>
              <ButtonCustom label='Confirmar' onClick={() => handleDelete()}></ButtonCustom>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </DashboardLayout>
  )
}

export default Clients