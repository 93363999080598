import React, { useEffect } from 'react'
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom'
import { Grid } from '@mui/material';
import * as S from './style'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { RiCalendarEventFill } from 'react-icons/ri'
import InputAdornment from '@mui/material/InputAdornment';
import Modal from 'components/Modal/Modal';
import moment from 'moment';
import 'moment/locale/pt-br';
import MaskMoney from 'components/Masks/MaskMoney';
import showToast from 'components/Toast/Toast';
import FormHelperText from '@mui/material/FormHelperText';
import { Formik, Form, useFormik } from 'formik';
import * as Yup from "yup";
import flatpickr from "flatpickr";
import "assets/theme/custom-flatpickr.css";
import { flatpickrOptions } from 'utils/utils';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { IoAdd } from "react-icons/io5";
import VuiAvatar from "components/VuiAvatar";
import TagsModal from "../CardModal/components/TagsModal";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import { curves, branches } from 'utils/utils';
import { createBriefingProject } from 'utils/requests/project';
import { createSimpleLead } from 'utils/requests/leads';
import { getAllInfluencers } from 'utils/requests/auth';
import { getAllClients } from 'utils/requests/leads';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#e0e0e0',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}));

const Avatar = ({ img, theme, children, ...rest }) => (
  <VuiAvatar
    src={img}
    size="sm"
    {...rest}
    sx={({ borders: { borderWidth }, palette: { light }, functions: { rgba } }) => ({
      border: `${borderWidth[2]} solid ${rgba(light.focus, 0.3)}`,
      cursor: "pointer",
      position: "relative",
      "&:hover, &:focus": {
        zIndex: "10",
      },
    })}
  >
    {children}
  </VuiAvatar>
)

const CreateCardModal = ({ refetch, closeModal }) => {
  moment.locale("pt-br")

  const [newLeadOpen, setNewLeadOpen] = React.useState(false);
  const [activeInfluencers, setActiveInfluencers] = React.useState();
  const [leadsArr, setLeadsArr] = React.useState();
  const [openTagModal, setOpenTagModal] = React.useState(false);
  const [validateFields, setValidateFields] = React.useState(false);
  const [validateLeadFields, setValidateLeadFields] = React.useState(false);

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      title: '',
      budget: '',
      endDate: '',
      paymentDate: '',
      status: '',
      miscInfo: '',
      influencers: [],
      lead: '',
      tags: [],
      projectStatus: ''
    },

    validateOnChange: validateFields,
    validateOnBlur: false,

    validationSchema: Yup.object({
      title: Yup.string().required("Título é um campo obrigatório"),
      budget: Yup.string(),
      endDate: Yup.string(),
      paymentDate: Yup.string(),
      status: Yup.string().required('Status é um campo obrigatório'),
      miscInfo: Yup.string(),
      influencers: Yup.array().min(1, 'Necessário pelo menos um influencer vinculado'),
      lead: Yup.object().required("Empresa é um campo obrigatório"),
      tags: Yup.array().notRequired(),
      projectStatus: Yup.object()
    }),
    onSubmit: async (values) => {
      const cleanValue = Number(values.budget.replace(/[a-zA-Z$]+|\./g, "").replace(/,/g, "."));
      const unixDate = (date) => {
        return Number(moment(date, 'DD/MM/YYYY HH:mm').valueOf());
      };
      const newCard = {
        title: values.title ?? '',
        influencers: values.influencers ? values.influencers.map((influencer) => influencer?.id) : '',
        description: values.miscInfo ?? '',
        status: values?.projectStatus?.id ?? '',
        budget: {
          paymentDateMs: values.paymentDate ? unixDate(values.paymentDate) : '',
          paymentDateExpectedMs: values.endDate ? unixDate(values.endDate) : '',
          value: cleanValue ? cleanValue * 100 : '',
          status: values.status ?? '',
        },
        pipeline: {
          leadsRelated: values.lead.id ?? '',
          tags: values.tags.length > 0 ? values.tags : '',
        }
      };
      Object.keys(newCard).forEach(key => {
        if (typeof newCard[key] === 'object' && Object.keys(newCard[key]).length > 0) {
          Object.entries(newCard[key]).forEach(entry => {
            if (entry[1] === '') {
              delete newCard[key][entry[0]];
            }
          })
        }
        if (typeof newCard[key] === 'object' && Object.keys(newCard[key]).length === 0) {
          delete newCard[key];
        };
        if ((Array.isArray(newCard[key])) && (newCard[key].length === 0)) {
          delete newCard[key];
        };
        if (newCard[key] === '') {
          delete newCard[key];
        };
      });

      createBriefingProject(newCard).then((res) => {
        if (!res.message) { showToast("Negócio criado com sucesso", "success"); refetch(); closeModal() };
        if (res.message) { showToast("Falha na criação de negócio", "error") };
      });
    }
  });

  const leadFormik = useFormik({
    enableReinitialize: true,

    validateOnChange: validateLeadFields,
    validateOnBlur: false,

    initialValues: {
      businessName: '',
      businessDescription: '',
      branch: '',
      curve: '',
    },

    validationSchema: Yup.object({
      businessName: Yup.string().required('Nome da empresa é obrigatório'),
      businessDescription: Yup.string().required('Descrição é obrigatório'),
      branch: Yup.string().required('Ramo é obrigatório'),
      curve: Yup.string().required('Curva é obrigatório'),
    }),
    onSubmit: async (values) => {
      const clientValues = {
        businessName: values.businessName ? values.businessName : '',
        businessDescription: values.businessDescription ? values.businessDescription : '',
        branch: values.branch ? values.branch : '',
        curve: values.curve ? values.curve : '',
      };

      Object.keys(clientValues).forEach(key => {
        if (clientValues[key] === '') {
          delete clientValues[key];
        };
      });

      if (Object.keys(clientValues).length > 0) {
        await createSimpleLead(clientValues).then((res) => {
          if (res?.message) {
            showToast('Falha na criação de empresa', 'error');
          };
          if (!res?.message) {
            showToast('Empresa criada com sucesso!', 'success');
            setNewLeadOpen(false);
            leadFormik.resetForm();
            fetchLeads();
            formik.setFieldValue('lead', res);
          };
        });
      }
      if (Object.keys(clientValues).length === 0) showToast('Sem dados alterados', 'warning');

    }
  });

  const getActiveInfluencers = async () => {
    const data = await getAllInfluencers({ onlyActive: true });
    if (!data.message) {
      setActiveInfluencers(data);
    };
  };

  const fetchLeads = async () => {
    const data = await getAllClients();
    if (!data.message) {
      setLeadsArr(data);
    };
  };

  const handleStatusChange = (event) => {
    formik.setFieldValue('status', event.target.value);
  };

  const handleNewLeadClose = () => {
    setNewLeadOpen(false);
    formik.setFieldValue('lead', null);
    leadFormik.resetForm();
  };

  useEffect(() => {
    fetchLeads();
    getActiveInfluencers();
  }, []);

  useEffect(() => {
    if (formik?.values?.lead?.id === 'Criar nova empresa +') {
      setNewLeadOpen(true);
    };
  }, [formik.values.lead]);

  flatpickr("#date", flatpickrOptions('endDate', formik.setFieldValue, formik.setFieldTouched));
  flatpickr(".paymentForeseenDate", flatpickrOptions('paymentDate', formik.setFieldValue, formik.setFieldTouched));

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const filter = createFilterOptions();

  const statusList = [
    {
      name: 'Leads 🔥',
      id: 'LEADS'
    },
    {
      name: 'Relacionamento 🎯',
      id: 'RELATIONSHIP'
    },
    {
      name: 'FUP - Leads 🆙',
      id: 'FOLLOW_UP'
    },
    {
      name: 'Em Negociação 💰',
      id: 'NEGOTIATION'
    },
    {
      name: 'Contrato Fechado 🤝',
      id: 'CLOSED_CONTRACT'
    },
  ];

  return (
    <>
      <Formik initialValues={formik.initialValues} validationSchema={formik.validationSchema} onSubmit={formik.handleSubmit} validateOnChange validateOnBlur validateOnMount>
        <Form style={{ width: '100%' }}>
          <p>Novo negócio</p>
          <Grid container>
            <Grid container flexDirection='column' gap='20px' marginTop='30px'>
              <Grid container justifyContent='space-between'>
                <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                  <S.Input
                    name='title'
                    id='title'
                    hiddenLabel
                    type='text'
                    placeholder="Título"
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.title}
                    error={Boolean(formik.errors.title) && formik.touched.title}
                    helperText={formik.touched.title ? formik.errors.title : false}
                  />
                </S.InputGrid>
                <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                  <S.Input
                    name='budget'
                    id='budget'
                    hiddenLabel
                    placeholder={"Valor(R$)"}
                    fullWidth

                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.budget}
                    InputProps={{ inputComponent: MaskMoney }}
                    error={Boolean(formik.errors.budget) && formik.touched.budget}
                    helperText={formik.touched.budget ? formik.errors.budget : false}
                  />
                </S.InputGrid>
              </Grid>
              <Grid container justifyContent='space-between'>
                <S.DateInputGrid item xs={12} md={5.5} xl={5.5} id='date'>
                  <S.Input
                    name='endDate'
                    id='endDate'
                    hiddenLabel
                    type='text'
                    placeholder="Data de Fechamento"
                    fullWidth
                    value={formik.values.endDate}
                    data-input
                    autoComplete='off'
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <RiCalendarEventFill />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position='end'>
                          <CloseIcon onClick={() => formik.setFieldValue('endDate', '')} sx={{ cursor: "pointer", "&:hover": { color: "#E22B00" } }} />
                        </InputAdornment>
                      )
                    }}
                    error={Boolean(formik.errors.endDate) && formik.touched.endDate}
                    helperText={formik.touched.endDate ? formik.errors.endDate : false}
                  />
                </S.DateInputGrid>
                <S.DateInputGrid item xs={12} md={5.5} xl={5.5} className='paymentForeseenDate'>
                  <S.Input
                    name='paymentForeseenDate'
                    id='paymentDate'
                    hiddenLabel
                    type='text'
                    placeholder="Previsão de pagamento"
                    fullWidth
                    value={formik.values.paymentDate}
                    data-input
                    autoComplete='off'
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <RiCalendarEventFill />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position='end'>
                          <CloseIcon onClick={() => formik.setFieldValue('paymentDate', '')} sx={{ cursor: "pointer", "&:hover": { color: "#E22B00" } }} />
                        </InputAdornment>
                      )
                    }}
                    error={Boolean(formik.errors.paymentDate) && formik.touched.paymentDate}
                    helperText={formik.touched.paymentDate ? formik.errors.paymentDate : false}
                  />
                </S.DateInputGrid>
              </Grid>
              <Grid container justifyContent='space-between'>
                <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                  <Autocomplete
                    disablePortal
                    id='lead'
                    value={formik.values?.lead ? formik.values?.lead : null}
                    onChange={(e, value) => {
                      formik.setFieldValue('lead', value)
                    }}
                    options={leadsArr ? leadsArr[0] : []}
                    sx={{ width: "100%" }}
                    getOptionLabel={(option) => option.businessName}
                    renderInput={(params) => <S.Input {...params} placeholder='Empresa' />}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      filtered.push({
                        businessName: "Criar nova empresa +", id: "Criar nova empresa +"
                      });

                      return filtered;
                    }}
                  />
                  {(Boolean(formik.errors.lead)) && <FormHelperText sx={{ color: "#f44336", marginLeft: "14px" }}>{formik.errors.lead}</FormHelperText>}
                </S.InputGrid>
                <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                  <Autocomplete
                    id="influencers"
                    name="influencers"
                    disablePortal
                    disableCloseOnSelect
                    multiple
                    value={formik.values.influencers ? formik.values.influencers : []}
                    onChange={(e, value) => {
                      formik.setFieldValue('influencers', value)
                    }}
                    options={activeInfluencers ? activeInfluencers[0] : []}
                    sx={{ width: "100%" }}
                    renderInput={(params) => <S.Input {...params} placeholder='Influencers' />}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.name}
                      </li>
                    )}
                  />
                  {(Boolean(formik.errors.influencers)) && <FormHelperText sx={{ color: "#f44336", marginLeft: "14px" }}>{formik.errors.influencers}</FormHelperText>}
                </S.InputGrid>
              </Grid>
              <Grid container>
                <S.InputGrid item md={12} xl={12}>
                  <S.Input
                    hiddenLabel
                    name='miscInfo'
                    id='miscInfo'
                    type='text'
                    placeholder="Observações"
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.miscInfo}
                  />
                </S.InputGrid>
              </Grid>
              <Grid container justifyContent='space-between'>
                <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                  <Autocomplete
                    disablePortal
                    id='projectStatus'
                    value={formik.values?.projectStatus ? formik.values?.projectStatus : null}
                    onChange={(e, value) => {
                      formik.setFieldValue('projectStatus', value)
                    }}
                    options={statusList}
                    sx={{ width: "100%" }}
                    getOptionLabel={(option) => option?.name}
                    renderInput={(params) => <S.Input {...params} placeholder='Status do Negócio' />}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  />
                </S.InputGrid>
                <Grid item md={5.5} xl={5.5}>
                  <FormControl>
                    <FormLabel sx={{ color: "#fff" }}>Status de pagamento:</FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={formik.values.status}
                      onChange={handleStatusChange}
                    >
                      {Boolean(formik.errors.status) && <FormHelperText sx={{ color: "#f44336" }}>Status é um campo obrigatório</FormHelperText>}
                      <Grid container gap='30px'>
                        <FormControlLabel value="PAGO" control={<Radio />} label="Pago" />
                        <FormControlLabel value="PENDENTE" control={<Radio />} label="Pendente" />
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container>
                <Grid container item lg={6} flexDirection='column'>
                  <p>Etiquetas</p>
                  <Grid container mt='5px'>
                    {formik?.values?.tags?.map((tag, index) => (
                      <S.Tag key={index} style={{ backgroundColor: tag?.bgcolor }}>{tag?.title}</S.Tag>
                    ))
                    }
                    <LightTooltip title='Adicionar etiqueta'>
                      <div>
                        <Avatar onClick={() => setOpenTagModal(true)}>
                          <IoAdd />
                        </Avatar>
                      </div>
                    </LightTooltip>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container justifyContent='flex-end'>
            <ButtonCustom
              label='Salvar'
              type='submit'
              id='save'
              sx={{ width: "150px", fontSize: "18px", fontWeight: "500" }}
              onClick={() => setValidateFields(true)}
            />
          </Grid>
        </Form>
      </Formik>
      <Modal
        open={newLeadOpen}
        onClose={handleNewLeadClose}
        size={"sm"}
      >
        <Grid container flexDirection='column' gap='30px'>
          <p>Nova Empresa</p>
          <Formik initialValues={leadFormik.initialValues} validationSchema={leadFormik.validationSchema} onSubmit={leadFormik.handleSubmit}>
            <Form style={{ width: '100%' }}>
              <Grid container flexDirection='column' gap='15px'>
                <S.InputGrid item xl={12}>
                  <S.Input
                    fullWidth
                    name='businessName'
                    id='businessName'
                    placeholder='Nome da empresa'
                    onChange={leadFormik.handleChange}
                    onBlur={leadFormik.handleBlur}
                    value={leadFormik.values.businessName}
                    error={(Boolean(leadFormik.errors.businessName))}
                    helperText={leadFormik.errors.businessName}
                  />
                </S.InputGrid>
                <S.InputGrid item xs={12} md={12} xl={12}>
                  <Autocomplete
                    name='branch'
                    id='branch'
                    disablePortal
                    value={leadFormik.values.branch ? leadFormik.values.branch : null}
                    onChange={(e, value) => {
                      leadFormik.setFieldTouched('branch')
                      leadFormik.setFieldValue('branch', value ? value : '')
                    }}
                    options={branches ? branches : []}
                    sx={{ width: "100%" }}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => <S.Input {...params} placeholder='Ramo' />}
                    isOptionEqualToValue={(option, value) => option === value}
                  />
                  {(Boolean(leadFormik.errors.branch) && leadFormik.touched.branch) && <FormHelperText sx={{ color: "#f44336", marginLeft: "14px" }}>Ramo é um campo obrigatório</FormHelperText>}
                </S.InputGrid>
                <S.InputGrid item xs={12} md={12} xl={12}>
                  <Autocomplete
                    name='curve'
                    id='curve'
                    disablePortal
                    value={leadFormik.values.curve ? leadFormik.values.curve : null}
                    onChange={(e, value) => {
                      leadFormik.setFieldTouched('curve')
                      leadFormik.setFieldValue('curve', value ? value : '')
                    }}
                    options={curves ? curves : []}
                    sx={{ width: "100%" }}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => <S.Input {...params} placeholder='Curva' />}
                    isOptionEqualToValue={(option, value) => option === value}
                  />
                  {(Boolean(leadFormik.errors.curve) && leadFormik.touched.curve) && <FormHelperText sx={{ color: "#f44336", marginLeft: "14px" }}>Curva é um campo obrigatório</FormHelperText>}
                </S.InputGrid>
                <S.InputGrid item xs={12} md={12} xl={12}>
                  <S.Input
                    fullWidth
                    name='businessDescription'
                    id='businessDescription'
                    placeholder='Descrição'
                    onChange={leadFormik.handleChange}
                    onBlur={leadFormik.handleBlur}
                    value={leadFormik.values.businessDescription}
                    error={(Boolean(leadFormik.errors.businessDescription))}
                    helperText={leadFormik.errors.businessDescription}
                  />
                </S.InputGrid>
                <Grid container justifyContent='flex-end'>
                  <ButtonCustom
                    label='Salvar'
                    type='submit'
                    sx={{ width: "100px", height: "40px !important", fontSize: "18px", fontWeight: "500" }}
                    onClick={() => setValidateLeadFields(true)}
                  />
                </Grid>
              </Grid>
            </Form>
          </Formik>
        </Grid>
      </Modal>
      <Modal size='sm' open={openTagModal} onClose={() => setOpenTagModal(false)}>
        <TagsModal
          cardTags={formik.values.tags}
          setTags={formik.setFieldValue}
          /*           cardId={cardId}
                    refetch={fetchCardData} */
          closeModal={() => setOpenTagModal(false)}
        /*           commercialId={commercialId} */
        />
      </Modal>
    </>
  )
}

export default CreateCardModal