let REACT_APP_API_URL = process.env.REACT_APP_API_URL;
if (!process.env.REACT_APP_API_URL) REACT_APP_API_URL = process.env.REACT_APP_API_DEV_URL;

export const getEvents = async (filters) => {
  const token = localStorage.getItem("token");
  const categoriesQuery = filters?.categories?.length > 0 ? `?${filters.categories.map(type => `categories[]=${type}`).join('&')}` : '';
  const response = await fetch(REACT_APP_API_URL + `/dashboard/calendar${categoriesQuery}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};

export const createEvent = async (event) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/calendar`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(event),
  });
  const data = await response.json();
  return data;
};

export const getCalendarCategories = async (allCategories) => {
  const token = localStorage.getItem("token");
  const response = await fetch(
    REACT_APP_API_URL
    +
    `/dashboard/calendar/categories${allCategories ? `?allCategories=${allCategories}` : ''}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
    });
  const data = await response.json();
  return data;
};

export const deleteEvent = async (id) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/calendar/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
  });
  const data = await response.json();
  return data;
};

export const editEvent = async (id, edit) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + `/dashboard/calendar/${id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(edit),
  });
  const data = await response.json();
  return data;
};