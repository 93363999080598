import styled from "styled-components"
import { Grid, Button, Fab } from "@mui/material"
import Card from "@mui/material/Card";

export const InfluencerGrid = styled(Grid)`
  padding-top: 5px;

  @media (max-width: 1300px){
    flex-direction: column !important;
  }

  :hover {
    cursor: pointer;
  }

  .influencerPhoto {
    border-radius: 12px;
  }
`

export const TableCard = styled(Card)`
  padding: 0 22px !important;
  
  .filterIcon {
    @media (max-width: 1600px) {
      margin-right: 18px;
    }
    @media (max-width: 992px) {
      margin-right: 0px;
    }
    margin-right: 24px;
  }
`

export const UserTextGrid = styled(Grid)`

  padding-top: 5px;
  
/*   @media (min-width: 1300px) {
    margin-left: 20px !important;
  } */
  .name{
    font-weight: 700;
    color: #ffffff;
    font-size: 16px;
  }
`
export const ThemeGrid = styled(Grid)`
  .name{
    font-weight: 700;
    color: #ffffff;
    font-size: 16px;
  }

  .tags{
    font-size: 14px;
  }
`

export const StatusGrid = styled(Grid)`
  align-items: center;
  gap: 10px;
  flex-wrap: nowrap !important;

  .status {
    font-weight: 500;
    color: #ffffff;
    font-size: 12px;
  }
`

export const SocialsGrid = styled(Grid)`
  gap: 20px;

  @media (max-width: 992px) {
    flex-wrap: wrap !important;
    justify-content: center;
  }

  .active {
    img {
      cursor: pointer;
    }
  }

  .disabled {
    filter: grayscale(1);
  }
`

export const ReachGrid = styled(Grid)`
  margin-top: 15px;
  flex-wrap: nowrap !important;
`

export const EditButton = styled(Button)`
  width: 41px !important;
  min-width: 30px !important;
  height: 41px !important;
  border-radius: 12px !important;
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 100%) !important;
`

export const RankingPhoto = styled(Grid)`
  .photoContainer {
    overflow: hidden;
    border-radius: 10px;
    width: 45px;
    height: 50px;
    margin-top: 5px;

    img {
      width: 100%;
      height: 100%;
      text-align: center;
      object-fit: cover;
      color: transparent;
      text-indent: 10000px;
      height: auto;
    }
  }
`

export const ModalFab = styled(Fab)`
  position: fixed !important;
  bottom: 50px;
  right: 0px;
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 155.15%), #C4C4C4;
  transition: all .2s ease-in-out !important;
  margin-right: 30px !important;
    
  &:hover{
    transform: scale(1.3);
  }
`

export const OptionsContainer = styled(Grid)`
  position: absolute;
  right: 50px;
`

export const OptionsGrid = styled(Grid)`
  border: 1px solid grey;
  padding: 10px;
  gap: 10px;
  background: #000;
  border-radius: 10px;
  z-index: 999 !important;

  p{
    cursor: pointer;
  }

  p:hover{
    color: #FC6009;
  }
`

export const Divider = styled.hr`
  border: 0;
  height: 1px;
  background: #2D3748;
`