import { Grid } from '@mui/material'
import moment from 'moment';
import 'moment/locale/pt-br';

const TransferDetails = ({ entry }) => {
  moment.locale("pt-br");

  return (
    <>
      <Grid container justifyContent='space-between'>
        <Grid item xs={12} md={5.85} xl={5.85}>
          <p className='subTitle'>Valor(R$):</p>
          <p>{entry?.value ? Number((entry?.value / 100).toFixed(2)).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ''}</p>
        </Grid>
        <Grid item xs={12} md={5.85} xl={5.85}>
          <p className='subTitle'>Influencer:</p>
          <p>{entry?.subCategories && entry?.subCategories.map((sub) => (sub.name)).join(", ")}</p>
        </Grid>
      </Grid>
      <Grid container justifyContent='space-between'>
        <Grid item xs={12} md={5.85} xl={5.85}>
          <p className='subTitle'>Descrição:</p>
          <p>{entry?.description ? entry?.description : ''}</p>
        </Grid>
        <Grid item xs={12} md={5.85} xl={5.85} sx={{ overflowWrap: 'break-word' }}>
          <p className='subTitle'>Conta Bancária:</p>
          <p>{entry?.bankAccount ? entry?.bankAccount : ''}</p>
        </Grid>
      </Grid>
      <Grid container justifyContent='space-between'>
        <Grid item xs={12} md={5.85} xl={5.85}>
          <p className='subTitle'>Data de fechamento:</p>
          <p>{entry?.dateInvoiced ? moment(entry?.dateInvoiced).format('L') : ''}</p>
        </Grid>
        <Grid item xs={12} md={5.85} xl={5.85}>
          <p className='subTitle'>Previsão de pagamento:</p>
          <p>{entry?.datePaymentExpected ? moment(entry?.datePaymentExpected).format('L') : ''}</p>
        </Grid>
      </Grid>
      <Grid container justifyContent='space-between'>
        <Grid item xs={12} md={5.85} xl={5.85}>
          <p className='subTitle'>Empresa:</p>
          {entry?.leadRelated ? entry?.leadRelated?.businessName : ''}
        </Grid>
        <Grid item xs={12} md={5.85} xl={5.85}>
          <p className='subTitle'>Marca:</p>
          {entry?.brand ? entry?.brand : ''}
        </Grid>
      </Grid>
      <Grid container justifyContent='space-between'>
        <Grid item xs={12} md={5.85} xl={5.85}>
          <p className='subTitle'>Observações:</p>
          <p className='wrap'>{entry?.miscInfo ? entry?.miscInfo : ''}</p>
        </Grid>
        <Grid item xs={12} md={5.85} xl={5.85}>
          <p className='subTitle'>Status:</p>
          <p>{entry?.status ? entry?.status : ''}</p>
        </Grid>
      </Grid>
    </>
  )
}

export default TransferDetails