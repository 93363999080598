import React from 'react'
import { Grid, Snackbar, Alert } from '@mui/material'
import * as S from "./style"
import InputAdornment from '@mui/material/InputAdornment';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import MailIcon from '@mui/icons-material/Mail';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import * as Yup from "yup";
import { useFormik } from "formik";
import Viraliza from '../../assets/viraliza.svg'
import Viuzz from '../../assets/viuzz.svg'
import { useNavigate } from 'react-router-dom';
import { login } from 'utils/requests/auth';
import { TokenContext } from 'context/TokenContext';

const Login = () => {
  const navigate = useNavigate();

  const [position, setPosition] = React.useState('')
  const [showPassword, setShowPassword] = React.useState(false)
  const [success, setSuccess] = React.useState(false)
  const [fail, setFail] = React.useState(false)

  const { setUserToken } = React.useContext(TokenContext);

  const handleLogin = async (email, password) => {
    /*     navigator.geolocation.getCurrentPosition(async (position) => {
          const longitude = position.coords.longitude;
          const latitude = position.coords.latitude;
        }); */
    await login(email, password).then((res) => {
      if (!res.status) {
        setSuccess(true)
        setUserToken(res.token);
        localStorage.setItem("token", res.token);
      }
      if (res.status) {
        setFail(true);
      }
    });
  };

  const token = localStorage.getItem("token");

  React.useEffect(() => {
    if (token) {
      navigate("/")
    };
  }, [token]);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email").email(),
      password: Yup.string().required("Please Enter Your Password").min(6),
    }),
    onSubmit: (values) => {
      handleLogin(values.email, values.password);
    }
  })

  const handleCloseFail = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setFail(false);
  };

  return (
    <>
      <S.MainContainer container justifyContent='center'>
        <Snackbar open={success} autoHideDuration={3000} sx={{ width: "400px" }} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
          <Alert severity="success" variant='filled' sx={{ width: '100%', color: "#fff" }}>
            Logado com sucesso! Redirecionando...
          </Alert>
        </Snackbar>
        <Snackbar open={fail} autoHideDuration={3000} onClose={handleCloseFail} sx={{ width: "400px" }} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
          <Alert severity="error" onClose={handleCloseFail} variant='filled' sx={{ width: '100%', backgroundColor: "rgb(211, 47, 47)" }}>
            Email ou senha inválidos
          </Alert>
        </Snackbar>
        <S.BgGrid container>
          <Grid container flexDirection='column' justifyContent='space-evenly'>
            <img src={Viraliza} />
            <form
              onSubmit={validation.handleSubmit}
            >
              <Grid container flexDirection='column' sx={{ marginBottom: "70px" }}>
                <S.BoxGrid container flexDirection='column'>
                  <p>Login</p>
                  <hr className='gradientBar'></hr>
                </S.BoxGrid>
                <S.InputGrid container flexDirection='column'>
                  <S.Input
                    hiddenLabel
                    name='email'
                    type='email'
                    placeholder="Login"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ""}
                    /* error={validation.errors.email ? true : false} */
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <MailIcon />
                        </InputAdornment>
                      )
                    }}
                  />
                  <S.Input
                    name='password'
                    type={showPassword ? 'text' : 'password'}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.password || ""}
                    hiddenLabel
                    placeholder="Senha"
                    /*                   error={validation.errors.password ? true : false} */
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <LockOpenIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position='end'>
                          {
                            showPassword
                              ?
                              <VisibilityIcon onClick={() => setShowPassword(false)} sx={{ cursor: 'pointer' }} />
                              :
                              <VisibilityOffIcon onClick={() => setShowPassword(true)} sx={{ cursor: 'pointer' }} />
                          }
                        </InputAdornment>
                      )
                    }}
                  />
                </S.InputGrid>
                {/*                 <S.ForgottenPassGrid container>
                  <p onClick={() => navigate('/recuperarsenha')}>Esqueceu sua senha?</p>
                </S.ForgottenPassGrid> */}
              </Grid>
              <S.LoginButton type='submit' disabled={(validation.errors.email || validation.errors.password) ? true : false}>Entrar</S.LoginButton>
            </form>
            <img src={Viuzz} />
          </Grid>
        </S.BgGrid>
      </S.MainContainer>
    </>
  )
}

export default Login