import { useState, useEffect } from 'react';
import { Grid, InputAdornment, FormHelperText } from '@mui/material'
import placeholder from 'assets/placeholder.png'
import { Formik, Form, useFormik } from 'formik';
import * as S from './style'
import * as Yup from "yup";
import { RiInstagramLine, RiYoutubeFill, RiTiktokFill, RiTwitterFill, RiSpotifyFill, RiCake2Fill, RiCameraFill } from "react-icons/ri"
import { useFilePicker } from 'react-sage';
import showToast from 'components/Toast/Toast';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';
import VuiAvatar from "components/VuiAvatar";
import CloseIcon from '@mui/icons-material/Close';
import flatpickr from "flatpickr";
import "assets/theme/custom-flatpickr.css";
import { flatpickrOptionsInfluencer } from 'utils/utils';
import { createInfluencer, getInfluencerTags } from 'utils/requests/auth';
import moment from 'moment';
import 'moment/locale/pt-br';
import { useNavigate } from "react-router-dom";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Modal from 'components/Modal/Modal';
import { createUser } from 'utils/requests/auth';

const Avatar = ({ img, theme, children, ...rest }) => (
  <VuiAvatar
    src={img}
    size="xxl"
    {...rest}
    sx={({ borders: { borderWidth }, palette: { light }, functions: { rgba } }) => ({
      /*       border: `${borderWidth[2]} solid ${rgba(light.focus, 0.3)}`,
            cursor: "pointer", */
      position: "relative",
      /*       "&:hover, &:focus": {
              zIndex: "10",
            }, */
    })}
  >
    {children}
  </VuiAvatar>
)

const CreateUser = ({ refetch, closeModal }) => {
  const [postAvatar, setPostAvatar] = useState();
  const [previewUrl, setPreviewUrl] = useState();
  const [triggerFlatpickr, setTriggerFlatpickr] = useState(false);
  const [validateFields, setValidateFields] = useState(false);

  const userTypes = [
    {
      name: 'Comercial',
      id: 'COMERCIAL'
    },
    {
      name: 'Head Comercial',
      id: 'H_COMERCIAL'
    },
    {
      name: 'Marketing',
      id: 'MARKETING'
    },
    {
      name: 'Head de Marketing',
      id: 'H_MARKETING'
    },
  ];

  const imageInput = useFilePicker({ maxFileSize: 1, resizeImage: true });

  useEffect(() => {
    const getDataUrls = async () => {
      if (imageInput.files && imageInput.files[0]) {
        setPostAvatar(imageInput.files[0]);
        const reader = new FileReader();
        reader.readAsDataURL(imageInput.files[0]);
        reader.onload = () => {
          setPreviewUrl(reader.result);
        };
      };
    };
    getDataUrls();
  }, [imageInput.files]);

  useEffect(() => {
    setTriggerFlatpickr(true);
  }, []);

  const unixDate = (date) => {
    return Number(moment(date, 'DD/MM/YYYY').valueOf());
  };

  const formik = useFormik({
    validateOnChange: validateFields,
    validateOnBlur: false,

    initialValues: {
      name: '',
      email: '',
      birthDate: '',
      bio: '',
      type: null,
    },
    validationSchema: Yup.object({
      name: Yup.string().min(4).required("Nome é obrigatório"),
      email: Yup.string().min(4).email().required("email é obrigatório"),
      birthDate: Yup.string(),
      bio: Yup.string(),
      type: Yup.object().required("Tipo é obrigatório"),
    }),
    onSubmit: async (values) => {
      const userValues = {
        name: values.name ? values.name : '',
        email: values.email ? values.email : '',
        birthDateMs: values.birthDate ? unixDate(values.birthDate) : '',
        bio: values.bio ? values.bio : '',
        type: values.type ? values?.type?.id : '',
        avatar: postAvatar ?? '',
        active: true
      };

      Object.keys(userValues).forEach(key => {
        if (userValues[key] === '') {
          delete userValues[key];
        };
      });

      Object.keys(userValues).forEach(key => {
        if ((Array.isArray(userValues[key])) && (userValues[key].length === 0)) {
          delete userValues[key];
        };
      });

      if (Object.keys(userValues).length > 0) {
        await createUser(userValues).then((res) => {
          if (res?.message) {
            showToast('Falha na criação de usuário', 'error');
          };
          if (!res?.message) {
            showToast('Usuário criado com sucesso!', 'success');
            closeModal();
            refetch();
          };
        });
      }
      if (Object.keys(userValues).length === 0) showToast('Sem dados alterados, campos sem alterações', 'warning');
    }
  });

  flatpickr("#birthDate", flatpickrOptionsInfluencer('birthDate', formik.setFieldValue, formik.setFieldTouched));

  return (
    <Grid container flexDirection='column'>
      <p>Criar Usuário</p>
      <Grid container justifyContent='center'>
        <S.InfluencerPhoto>
          <div>
            <Avatar img={previewUrl ?? placeholder} />
          </div>
          <S.IndicatorGrid onClick={imageInput.onClick}>
            <S.BranchIndicator>
              <RiCameraFill size='20px' color='#fff' style={{ alignSelf: 'center' }} />
              <imageInput.HiddenFileInput accept=".jpg, .jpeg, .png" />
            </S.BranchIndicator>
          </S.IndicatorGrid>
        </S.InfluencerPhoto>
      </Grid>
      <Formik initialValues={formik.initialValues} validationSchema={formik.validationSchema} onSubmit={formik.handleSubmit}>
        <Form>
          <Grid container flexDirection='column' gap='20px' mt='15px'>
            <Grid container justifyContent='space-between'>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  hiddenLabel
                  name='name'
                  placeholder="Nome"
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name || ""}
                  error={formik.touched.name && formik.errors.name}
                  helperText={formik.touched.name && formik.errors.name ? 'Nome inválido' : false}
                />
              </S.InputGrid>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                  <S.Input
                    hiddenLabel
                    name='email'
                    placeholder="Email"
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email || ""}
                    error={formik.errors.email}
                    helperText={formik.errors.email ? 'Email inválido' : false}
                  />
                </S.InputGrid>
            </Grid>
            <Grid container justifyContent='space-between'>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <Autocomplete
                  id='type'
                  disablePortal
                  disableClearable
                  value={formik.values?.type ? formik.values?.type : null}
                  onChange={(e, value) => {
                    formik.setFieldValue('type', value)
                  }}
                  options={userTypes}
                  sx={{ width: "100%" }}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => <S.Input {...params} error={formik.errors.type} placeholder='Tipo' />}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                />
                {(Boolean(formik.errors.type)) &&
                  <FormHelperText sx={{ color: "#f44336", marginLeft: "14px" }}>
                    Tipo é um campo obrigatório
                  </FormHelperText>
                }
              </S.InputGrid>
              <S.InputGrid item xs={12} md={5.85} xl={5.85} id='birthDate'>
                <S.Input
                  name='birthDate'
                  hiddenLabel
                  type='text'
                  placeholder="Data de Nascimento"
                  fullWidth
                  data-input
                  autoComplete='off'
                  value={formik.values.birthDate}
                  error={Boolean(formik.errors.birthDate)}
                  helperText={formik.errors.birthDate}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <RiCake2Fill />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position='end'>
                        <CloseIcon onClick={() => formik.setFieldValue('birthDate', '')} sx={{ cursor: "pointer", "&:hover": { color: "#E22B00" } }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </S.InputGrid>
            </Grid>
            <Grid container gap='20px' justifyContent='space-between'>
              <S.InputGrid item xs={12} xl={12}>
                <S.Input
                  hiddenLabel
                  name='bio'
                  placeholder="Descrição"
                  fullWidth
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.bio || ""}
                />
              </S.InputGrid>
            </Grid>
          </Grid>
          <Grid container justifyContent='flex-end' mt='15px'>
            <ButtonCustom
              label='Criar Usuário'
              type='submit'
              sx={{ width: "150px", height: "40px !important", fontSize: "18px", fontWeight: "500" }}
              onClick={() => setValidateFields(true)}
            />
          </Grid>
        </Form>
      </Formik>
    </Grid>
  )
}

export default CreateUser