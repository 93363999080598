import { useState, useEffect } from 'react';
import { Grid, InputAdornment } from '@mui/material'
import placeholder from 'assets/leadsPlaceholder.png'
import { Formik, Form, useFormik } from 'formik';
import * as S from './style'
import * as Yup from "yup";
import { RiPhoneFill, RiCameraFill, RiMailFill } from "react-icons/ri"
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';
import { useFilePicker } from 'react-sage';
import { createContact } from 'utils/requests/leads';
import VuiAvatar from "components/VuiAvatar";
import showToast from 'components/Toast/Toast';

const Avatar = ({ img, theme, children, ...rest }) => (
  <VuiAvatar
    src={img}
    size="xxl"
    {...rest}
    sx={({ borders: { borderWidth }, palette: { light }, functions: { rgba } }) => ({
      position: "relative",
    })}
  >
    {children}
  </VuiAvatar>
)

const CreateContact = ({ leadInfo, refetch, closeModal }) => {
  const [postAvatar, setPostAvatar] = useState()
  const [previewUrl, setPreviewUrl] = useState();
  const [validateFields, setValidateFields] = useState(false);

  const imageInput = useFilePicker({ maxFileSize: 1, resizeImage: true });

  useEffect(() => {
    const getDataUrls = async () => {
      if (imageInput.files && imageInput.files[0]) {
        setPostAvatar(imageInput.files[0]);
        const reader = new FileReader();
        reader.readAsDataURL(imageInput.files[0]);
        reader.onload = () => {
          setPreviewUrl(reader.result);
        };
      };
    };
    getDataUrls();
  }, [imageInput.files]);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: validateFields,
    validateOnBlur: false,

    initialValues: {
      name: '',
      role: '',
      email: '',
      phone: '',

    },
    validationSchema: Yup.object({
      name: Yup.string().required('Nome é obrigatório'),
      role: Yup.string().required('Cargo é obrigatório'),
      email: Yup.string().required('Email é obrigatório'),
      phone: Yup.string().required('Telefone é obrigatório'),
    }),
    onSubmit: async (values) => {
      const contactValues = {
        ...values,
        avatar: postAvatar ?? '',
        linkedLeadId: leadInfo?.lead?.id
      };

      Object.keys(contactValues).forEach(key => {
        if (contactValues[key] === '') {
          delete contactValues[key];
        };
      });

      if (Object.keys(contactValues).length > 0) await createContact(contactValues).then((res) => {
        if (res?.message) {
          showToast('Falha na criação do contato', 'error');
        };
        if (!res?.message) {
          showToast('Contato criado com sucesso!', 'success');
          refetch();
          closeModal();
        };
      });
      if (Object.keys(contactValues).length === 0) showToast('Sem dados alterados, campos sem alterações', 'warning');
    }
  });

  return (
    <Grid container flexDirection='column' data-cy="create-contact-modal">
      <p>Criar Contato</p>
      <Grid container justifyContent='center'>
        <S.InfluencerPhoto>
          <div>
            <Avatar img={previewUrl ?? placeholder} />
          </div>
          <S.IndicatorGrid onClick={imageInput.onClick}>
            <S.BranchIndicator>
              <RiCameraFill size='20px' color='#fff' style={{ alignSelf: 'center' }} />
              <imageInput.HiddenFileInput accept=".jpg, .jpeg, .png" />
            </S.BranchIndicator>
          </S.IndicatorGrid>
        </S.InfluencerPhoto>
      </Grid>
      <Formik initialValues={formik.initialValues} validationSchema={formik.validationSchema} onSubmit={formik.handleSubmit}>
        <Form>
          <Grid container flexDirection='column' gap='15px' mt='20px'>
            <Grid container justifyContent='space-between'>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  name='name'
                  hiddenLabel
                  type='text'
                  placeholder="Nome"
                  fullWidth
                  data-input
                  autoComplete='off'
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  error={Boolean(formik.errors.name)}
                  helperText={formik.errors.name}
                />
              </S.InputGrid>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  name='role'
                  hiddenLabel
                  type='text'
                  placeholder="Cargo"
                  fullWidth
                  data-input
                  autoComplete='off'
                  onChange={formik.handleChange}
                  value={formik.values.role}
                  error={Boolean(formik.errors.role)}
                  helperText={formik.errors.role}
                />
              </S.InputGrid>
            </Grid>
            <Grid container justifyContent='space-between'>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  name='email'
                  hiddenLabel
                  type='text'
                  placeholder="Email"
                  fullWidth
                  data-input
                  autoComplete='off'
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  error={Boolean(formik.errors.email)}
                  helperText={formik.errors.email}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <RiMailFill />
                      </InputAdornment>
                    )
                  }}
                />
              </S.InputGrid>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  name='phone'
                  hiddenLabel
                  type='text'
                  placeholder="Telefone"
                  fullWidth
                  data-input
                  autoComplete='off'
                  onChange={formik.handleChange}
                  value={formik.values.phone}
                  error={Boolean(formik.errors.phone)}
                  helperText={formik.errors.phone}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <RiPhoneFill />
                      </InputAdornment>
                    )
                  }}
                />
              </S.InputGrid>
            </Grid>
          </Grid>
          <Grid container justifyContent='flex-end' mt='15px'>
            <ButtonCustom
              label='Salvar'
              type='submit'
              sx={{ width: "150px", height: "40px !important", fontSize: "18px", fontWeight: "500" }}
              onClick={() => setValidateFields(true)}
            />
          </Grid>
        </Form>
      </Formik>
    </Grid>
  )
}

export default CreateContact