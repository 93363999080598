import React from 'react'
import DashboardLayout from "defaultComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "defaultComponents/Navbars/DashboardNavbar";
import Datepicker from 'components/Datepicker/Datepicker';
import { Grid } from '@mui/material';
import * as S from '../Accounting/style'
import Table from "defaultComponents/Tables/Table";
import { RiEyeFill } from "react-icons/ri";
import useMediaQuery from '@mui/material/useMediaQuery';
import moment from 'moment';
import 'moment/locale/pt-br';
import Card from "@mui/material/Card";
import Pagination from '@mui/material/Pagination';
import CreditBalance from 'layouts/billing/components/CreditBalance';
import viuzzDoc from 'assets/doc.png'
import { getPdfData } from 'utils/requests/other';
import { TokenContext } from 'context/TokenContext';
import Modal from 'components/Modal/Modal';
import InfluencerFinanceDetails from './ModalContent/InfluencerFinanceDetails';
import { getInfluencerFinancial } from 'utils/requests/financial';
import { jsPDF } from "jspdf";
import { useParams } from 'react-router-dom';
import PieChart from 'defaultComponents/Charts/PieCharts/PieChart';

const InfluencerFinance = () => {
  moment.locale("pt-br")
  const [filterDate, setFilterDate] = React.useState();
  const [openMenu, setOpenMenu] = React.useState([]);
  const [pagination, setPagination] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [pdfMenu, setPdfMenu] = React.useState(false);
  const [openPdfMenu, setOpenPdfMenu] = React.useState([]);
  const [unixTimestamp, setUnixTimestamp] = React.useState(moment(Date.now()).endOf('month').subtract(15, 'days').valueOf());
  const [pdfData, setPdfData] = React.useState();
  const [financialData, setFinancialData] = React.useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [projectDetails, setProjectDetails] = React.useState();
  const [countryChart, setCountryChart] = React.useState({
    series: [],
    options: {
      chart: {
        type: 'donut',
      },
      labels: [],
      colors: ['#BE4E75', '#50859D', '#47C094', '#DB972C', '#744C75', '#5BADBF', '#92181C', '#545454'],
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: false
      },
      tooltip: {
        y: {
          title: {
            formatter: (label) => {
              const splitLabel = label.split('(');
              return splitLabel[0]
            },
          },
        },
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    }
  })

  const { decodedToken } = React.useContext(TokenContext);

  const params = useParams();

  const toggleOpenPdfMenu = (index) => {
    const updatedMenu = [...openPdfMenu];
    updatedMenu[index] = !updatedMenu[index];
    setOpenPdfMenu(updatedMenu);
  };

  const fetchPdfData = async () => {
    await getPdfData(params?.username ?? decodedToken?.username, unixTimestamp).then(res => {
      if (!res?.message) {
        setPdfData(res);
      };
    });
  };

  const fetchData = async () => {
    const startDate = filterDate ? moment(filterDate[0], "DD-MM-YYYY HH:mm:ss").valueOf() : '';
    const endDate = filterDate ? moment(filterDate[1], "DD-MM-YYYY HH:mm:ss").valueOf() : '';
    await getInfluencerFinancial(
      params?.username ?? decodedToken?.username,
      page - 1,
      10,
      ['INCOME', 'PENDING'],
      startDate,
      endDate
    ).then(res => {
      if (!res?.message && res !== financialData) {
        setFinancialData(res);
        setCountryChart(prevGraph => ({
          series: res?.businessEarningsPercentage?.data,
          options: {
            ...prevGraph.options,
            labels: res?.businessEarningsPercentage?.labels
          }
        }));
      };
    });
  };

  React.useEffect(() => {
    if (unixTimestamp) fetchPdfData();
  }, [unixTimestamp]);

  const callPdf = () => {
    if (pdfData) {
      getPdf(pdfData?.Dados);
    };
  }

  /*   React.useEffect(() => {
      if (pdfData) getPdf(pdfData?.Dados);
    }, [pdfData]); */

  const mobile = useMediaQuery("(max-width: 992px)");

  const calculatePagination = () => {
    const roundUpArrSize = Math.ceil(financialData?.financialEntries?.length / 10);
    const paginationArr = Array.from({ length: roundUpArrSize }, (value, index) => index)
    setPagination(paginationArr);
  };

  React.useEffect(() => {
    if (financialData) calculatePagination();
  }, [financialData]);

  React.useEffect(() => {
    fetchData();
  }, [page, filterDate]);

  React.useEffect(() => {
    callPdf();
  }, [unixTimestamp]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const capitalize = (str) => {
    const newString = str.charAt(0).toUpperCase() + str.slice(1)
    return newString;
  };

  const getPdf = (data) => {
    if (data) {
      const createHeaders = (keys) => {
        const result = [];
        for (let i = 0; i < keys.length; i += 1) {
          result.push({
            id: keys[i],
            name: keys[i],
            prompt: keys[i],
            width: 65,
            align: "center",
            padding: 0
          });
        }
        return result;
      }

      const headers = createHeaders([
        "Descrição",
        "Valor",
      ]);

      var doc = new jsPDF({ putOnlyUsedFonts: true });
      doc.addImage(viuzzDoc, "PNG", 0, 0, 210, 300);
      doc.setFontSize(24)
      doc.text(pdfData?.Influenciador, 10, 50)
      doc.setFontSize(18)
      doc.text(capitalize(moment(unixTimestamp).format("MMMM, YYYY")), 20, 60)
      doc.table(30, 80, pdfData?.Dados, headers, { fontSize: 15 });
      doc.save('viuzz.pdf')
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <S.MainGrid container spacing={3}>
        <Grid container item justifyContent='space-between'>
          <Grid item xs={12} lg={12} xl={7.95}>
            <Card sx={{ justifyContent: 'space-between' }}>
              <S.TableGrid container flexDirection='column'>
                <Grid container flexDirection='column'>
                  <Datepicker setDate={setFilterDate} allButton lifetime />
                  <Table
                    columns={[
                      { name: "descrição", align: "left" },
                      { name: "valor", align: "left" },
                      { name: "data", align: "left" },
                      { name: "status", align: "left" },
                      { name: "observações", align: "left" },
                    ]}
                    rows={
                      (financialData?.financialEntries && financialData?.financialEntries[0])
                      && financialData?.financialEntries[0]?.map((entry, index) => {
                        return {
                          descrição: (
                            <Grid container key={index}>
                              <Grid item md={8} xl={9}>
                                <p>{entry?.description?.length > (mobile ? 6 : 24) ? entry?.description?.substring(0, (mobile ? 6 : 24)) + "..." : entry?.description}</p>
                              </Grid>
                            </Grid>
                          ),
                          valor: (
                            <Grid container>
                              <p className={entry?.status === 'PENDENTE' ? 'transfer' : 'positive'}>
                                R$ {
                                  Number((entry?.value / 100).toFixed(2))
                                    .toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                }
                              </p>
                            </Grid>
                          ),
                          data: (
                            <p>{moment(entry?.dateInvoiced).format('L')}</p>
                          ),
                          status: (
                            <Grid container key={index}>
                              <Grid item md={8} xl={9}>
                                <p>{entry?.status === 'PENDENTE' ? 'PENDENTE' : 'ENTRADA'}</p>
                              </Grid>
                            </Grid>
                          ),
                          observações: (
                            <Grid container flexDirection='column'>
                              <Grid container justifyContent='space-between'>
                                <S.MiscInfoGrid item xs={11} xl={11}>
                                  <p>{
                                  entry?.miscInfo 
                                  ? 
                                  (entry?.miscInfo?.length > 50 ? entry?.miscInfo?.substring(0, 50) + "..." : entry?.miscInfo) 
                                  : 
                                  "-"
                                  }
                                  </p>
                                </S.MiscInfoGrid>
                                <Grid item xs={1} xl={1} onClick={() => { setProjectDetails(entry); setOpenModal(true); }}>
                                  <RiEyeFill size='20px' style={{ cursor: "pointer", zIndex: "9999" }} />
                                </Grid>
                              </Grid>
                            </Grid>
                          )
                        }
                      })}
                  />
                </Grid>
              </S.TableGrid>
              {pagination?.length > 0 &&
                <S.PaginationGrid container justifyContent='center' sx={{ marginTop: "20px" }}>
                  <Pagination count={pagination?.length} page={page} siblingCount={1} boundaryCount={1} onChange={handleChange} />
                </S.PaginationGrid>
              }
            </Card>
          </Grid>
          <Grid container flexDirection='column' xl={3.95} gap='20px'>
            <Grid item>
              <Card sx={{ padding: 0 }}>
                <CreditBalance
                  data={financialData}
                  state={pdfMenu}
                  setState={setPdfMenu}
                  getPdf={callPdf}
                  toggleOpenMenu={toggleOpenPdfMenu}
                  openMenu={openPdfMenu}
                  unixTimestamp={unixTimestamp}
                  setUnixTimestamp={setUnixTimestamp}
                  cashout={params?.username ?? decodedToken?.username}
                />
              </Card>
            </Grid>
            <Grid item>
              <Card sx={{ padding: 0 }}>
                <Grid item sx={{ paddingLeft: '30px', paddingBottom: '15px', paddingTop: "20px" }}>
                  <p>Lucro por Marca</p>
                  <S.PieChartGrid item xl={11.5}>
                    <PieChart key={countryChart.series} pieChartData={countryChart.series} pieChartOptions={countryChart.options} />
                  </S.PieChartGrid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </S.MainGrid>
      <Modal open={openModal} onClose={() => { setOpenModal(false) }}>
        <InfluencerFinanceDetails entry={projectDetails} />
      </Modal>
    </DashboardLayout>
  )
}

export default InfluencerFinance