import { Grid } from '@mui/material'
import React from 'react'
import moment from 'moment';
import 'moment/locale/pt-br';
import { IoAlertCircleOutline } from "react-icons/io5";
import * as S from './style'

const InfluencerFinanceDetails = ({ entry }) => {
  moment.locale("pt-br")
  return (
    <S.MainGrid container flexDirection='column' gap='15px'>
      <p style={{ fontSize: '24px' }}>Detalhes</p>
      <Grid container justifyContent='space-between'>
        <Grid item xs={12} md={5.85} xl={5.85}>
          <p className='subTitle'>Valor(R$):</p>
          <p>{entry?.value ? Number((entry?.value / 100).toFixed(2)).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : ''}</p>
        </Grid>
        <Grid item xs={12} md={5.85} xl={5.85}>
          <p className='subTitle'>Influencer:</p>
          <p>{entry?.subCategories && entry?.subCategories.map((sub) => (sub.name)).join(", ")}</p>
        </Grid>
      </Grid>
      <Grid container justifyContent='space-between'>
        <Grid item xs={12} md={5.85} xl={5.85}>
          <p className='subTitle'>Descrição:</p>
          <p>{entry?.description ? entry?.description : ''}</p>
        </Grid>
        <Grid item xs={12} md={5.85} xl={5.85} sx={{ overflowWrap: 'break-word' }}>
          <p className='subTitle'>Conta Bancária:</p>
          <p>{entry?.bankAccount ? entry?.bankAccount : ''}</p>
        </Grid>
      </Grid>
      <Grid container justifyContent='space-between'>
        <Grid item xs={12} md={5.85} xl={5.85}>
          <p className='subTitle'>Data de fechamento:</p>
          <p>{entry?.dateInvoiced ? moment(entry?.dateInvoiced).format('L') : ''}</p>
        </Grid>
        <Grid item xs={12} md={5.85} xl={5.85}>
          <p className='subTitle'>Previsão de pagamento:</p>
          <p>{entry?.datePaymentExpected ? moment(entry?.datePaymentExpected).format('L') : ''}</p>
        </Grid>
      </Grid>
      <Grid container justifyContent='space-between'>
        <Grid item xs={12} md={5.85} xl={5.85}>
          <p className='subTitle'>Empresa:</p>
          {entry?.leadRelated ? entry?.leadRelated?.businessName : ''}
        </Grid>
        <Grid item xs={12} md={5.85} xl={5.85}>
          <p className='subTitle'>Marca:</p>
          {entry?.brand ? entry?.brand : ''}
        </Grid>
      </Grid>
      <Grid container justifyContent='space-between'>
        <Grid item xs={12} md={5.85} xl={5.85}>
          <p className='subTitle'>Observações:</p>
          <p className='wrap'>{entry?.miscInfo ? entry?.miscInfo : ''}</p>
        </Grid>
        <Grid item xs={12} md={5.85} xl={5.85}>
          <p className='subTitle'>Status:</p>
          <p>{entry?.status ? entry?.status : ''}</p>
        </Grid>
      </Grid>
      <S.WarningGrid container gap='5px'>
        <IoAlertCircleOutline color='red' style={{ alignSelf: "center" }} />
        <p className='subtitle reminder'>Lembrete:</p>
        <p className='subtitle'>Este valor pode ser alterado até o dia do recebimento mensal.</p>
      </S.WarningGrid>
    </S.MainGrid>
  )
}

export default InfluencerFinanceDetails