import { useState, useContext } from 'react'
import DashboardLayout from "defaultComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "defaultComponents/Navbars/DashboardNavbar";
import { Grid } from '@mui/material';
import Tabs from "@mui/material/Tabs";
import { RiArrowLeftLine } from 'react-icons/ri';
import { IoAlertCircleOutline } from 'react-icons/io5';
import * as S from './style'
import GeneralForm from './forms/GeneralForm';
import CreativeForm from './forms/CreativeForm';
import MetricsForm from './forms/MetricsForm';
import * as T from "components/Toast/style"
import { useNavigate } from 'react-router-dom';
import { TokenContext } from "context/TokenContext";
import CommercialForm from './forms/CommercialForm';
import BudgetForm from './forms/BudgetForm';
import Modal from 'components/Modal/Modal';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';
import showToast from 'components/Toast/Toast';
import { editBriefingProject } from 'utils/requests/project';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import 'moment/locale/pt-br';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#e0e0e0',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}));

const CampaignProjectDetails = ({ project, influencerList, refetchProject }) => {
  moment.locale("pt-br");
  const navigate = useNavigate();

  const { decodedToken } = useContext(TokenContext);
  const role = decodedToken?.type;

  const [tabValue, setTabValue] = useState("general");
  const [openFinishProjectModal, setOpenFinishProjectModal] = useState(false);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const redirects = {
    ADMINISTRATOR: '/marketing/projetos',
    /* H_COMERCIAL: `/comercial/projetos` */
    H_MARKETING: '/marketing/projetos',
    MARKETING: '/marketing/projetos',
  };

  const handleProjectFinalization = async () => {
    await editBriefingProject(project?.id, { status: 'ENDED', endDateMs: moment().valueOf() }).then((res) => {
      if (!res?.message) {
        showToast("Projeto finalizado com sucesso!", "success");
        setOpenFinishProjectModal(false);
        refetchProject();
        return
      }
      showToast("Falha ao finalizar projeto", "error");
    })
  };

  return (
    <DashboardLayout>
      <DashboardNavbar customTitle={project?.title} />
      <T.ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Grid container spacing={3}>
        <Grid item md={3} xl={3}>
          <S.ProjectCard sx={{ width: "100%" }}>
            <Grid container flexDirection='column' justifyContent='space-between' height='100%'>
              <Grid container flexDirection='column'>
                <S.BackGrid container gap='10px' onClick={() => navigate(redirects[role])}>
                  <RiArrowLeftLine size='20px' style={{ alignSelf: "center" }} />
                  <p>Voltar</p>
                </S.BackGrid>
                <S.TabsGrid>
                  <Tabs
                    orientation='vertical'
                    value={tabValue}
                    onChange={handleSetTabValue}
                    sx={{ background: "transparent", display: "flex", justifyContent: "flex-end" }}
                    TabIndicatorProps={{ style: { borderRadius: "5px 5px 15px 5px", backgroundColor: "rgba(239, 241, 246, 0.10)", backdropFilter: "blur(15px)" } }}
                  >
                    <S.SideTabs sx={{ paddingLeft: "20px !important" }} label="Geral" value="general" />
                    <S.SideTabs sx={{ paddingLeft: "20px !important" }} label="Comercial" value="commercial" />
                    <S.SideTabs sx={{ paddingLeft: "20px !important" }} label="Criativos" value="creative" />
                    <S.SideTabs sx={{ paddingLeft: "20px !important" }} label="Orçamento🚧" value="budget" />
                    <S.SideTabs sx={{ paddingLeft: "20px !important" }} label="Métricas🚧" value="metrics" />
                  </Tabs>
                </S.TabsGrid>
              </Grid>
              {project?.status !== "ENDED" &&
                <S.FinishGrid container padding='4px'>
                  {!project?.canBeEnded &&
                    <LightTooltip
                      title='O projeto não pode ser finalizado até que seja pago, ou todas as atividades relacionadas concluídas.'
                      placement='top'
                    >
                      <S.FinishProjectButton
                        className={project?.canBeEnded ? '' : 'disabledButton'}
                        onClick={() => project?.canBeEnded ? setOpenFinishProjectModal(true) : ''}
                      >
                        Finalizar projeto
                      </S.FinishProjectButton>
                    </LightTooltip>
                  }
                  {project?.canBeEnded &&
                    <S.FinishProjectButton
                      className={project?.canBeEnded ? '' : 'disabledButton'}
                      onClick={() => project?.canBeEnded ? setOpenFinishProjectModal(true) : ''}
                    >
                      Finalizar projeto
                    </S.FinishProjectButton>
                  }
                </S.FinishGrid>
              }
            </Grid>
          </S.ProjectCard>
        </Grid>
        <Grid item md={9} xl={9}>
          {tabValue === "general" &&
            <GeneralForm project={project} influencerList={influencerList} refetch={refetchProject} />
          }
          {tabValue === "budget" &&
            <BudgetForm project={project} />
          }
          {tabValue === "creative" &&
            <CreativeForm project={project} creative={project?.creative} refetch={refetchProject} projectId={project?.id} />
          }
          {tabValue === "commercial" &&
            <CommercialForm project={project} refetch={refetchProject} />
          }
          {tabValue === "metrics" &&
            <MetricsForm project={project} />
          }
        </Grid>
      </Grid >
      <Modal size='md' open={openFinishProjectModal} onClose={() => setOpenFinishProjectModal(false)}>
        <Grid container flexDirection='column' gap='20px'>
          <p style={{ fontSize: "24px" }}>Tem certeza que deseja finalizar este projeto?</p>
          <Grid container gap='15px' justifyContent='center'>
            <Grid item xs={8} lg={6} mt='40px'>
              <ButtonCustom
                label='Autorizar Finalização'
                onClick={handleProjectFinalization}
                sx={{
                  background: "linear-gradient(180deg, #5ACC93 0%, #0E1B05 155.15%)",
                  fontSize: "16px"
                }}
              />
            </Grid>
          </Grid>
          <S.AlertGrid container gap='5px' mt='15px'>
            <IoAlertCircleOutline size='24px' color='red' style={{ alignSelf: "center" }} />
            <p className='bold'>Lembrete: </p>
            <p>Você não poderá voltar atrás.</p>
          </S.AlertGrid>
        </Grid>
      </Modal>
    </DashboardLayout >
  )
}

export default CampaignProjectDetails