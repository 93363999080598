import DashboardLayout from "defaultComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "defaultComponents/Navbars/DashboardNavbar";
import { Grid, Card } from '@mui/material';
import * as S from './style'
import BarChart from 'defaultComponents/Charts/BarCharts/BarChart';
import { useState, useEffect } from "react";
import { abbreviateNumbers } from "utils/utils";
import { getCommercialHomeGraphs } from "utils/requests/leads";
import CurrentYearMonthDatepicker from "components/Datepicker/CurrentYearMonthDatepicker";
import moment from 'moment';
import 'moment/locale/pt-br';

const Commercial = () => {
  moment.locale("pt-br");
  
  const [barChartData, setBarChartData] = useState(
    {
      series: [
        {
          name: '',
          data: []
        }
      ],
      options: {
        chart: {
          type: 'bar',
          height: 350,
          toolbar: {
            show: false
          },
        },
        plotOptions: {
          bar: {
            barHeight: '85%',
            distributed: true,
            horizontal: true,
            borderRadius: 15,
            borderRadiusApplication: "end",
            dataLabels: {
              position: 'bottom'
            },
          }
        },
        grid: {
          borderColor: "#56577A",
          strokeDashArray: 10,
          yaxis: {
            lines: {
              show: false,
            },
          },
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        legend: {
          show: false
        },
        stroke: {
          colors: ["transparent"],
          width: 5
        },
        colors: ['#44956B', '#D85308', '#D85308', '#D85308', '#D85308', '#D85308'],
        dataLabels: {
          enabled: false,
          textAnchor: 'start',
          style: {
            colors: ['#fff']
          },
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              colors: "#c8cfca",
              fontSize: "10px",
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: "#c8cfca",
              fontSize: "12px",
            },
          },
        },
        tooltip: {
          theme: 'dark',
          x: {
            show: false
          },
          y: {
            formatter: (val) => {
              return Math.floor(val);
            },
            title: {
              formatter: function () {
                return ''
              }
            }
          }
        }
      }
    }
  );
  const [leadsByUserChartData, setLeadsByUserChartData] = useState(
    {
      series: [
        {
          name: 'Leads Convertidos',
          data: [],
        },
      ],
      options: {
        chart: {
          type: 'bar',
          toolbar: {
            show: false
          },
        },
        tooltip: {
          style: {
            fontSize: "10px",
            fontFamily: "Plus Jakarta Display",
          },
          onDatasetHover: {
            style: {
              fontSize: "10px",
              fontFamily: "Plus Jakarta Display",
            },
          },
          theme: "dark",
        },
        xaxis: {
          categories: [],
          show: true,
          labels: {
            show: true,
            style: {
              colors: "#fff",
              fontSize: "10px",
            },
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          show: true,
          color: "#fff",
          min: 0,
          max: 100,
          tickAmount: 5,
          labels: {
            show: true,
            formatter: function (val) {
              return val + "%";
            },
            style: {
              colors: "#fff",
              fontSize: "10px",
              fontFamily: "Plus Jakarta Display",
            },
          },
        },
        grid: {
          strokeDashArray: 10,
          borderColor: "#56577A",
          yaxis: {
            lines: {
              show: true,
            },
          },
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        colors: ['#44956B', '#D85308', '#D85308', '#D85308', '#D85308', '#D85308'],
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            borderRadius: 5,
            columnWidth: "12px",
            distributed: true,
            borderRadiusApplication: "end",
          },
        },
        legend: {
          show: false
        },
        responsive: [
          {
            breakpoint: 768,
            options: {
              plotOptions: {
                bar: {
                  borderRadius: 5,
                },
              },
            },
          },
        ],
      }
    }
  );
  const [businessValueChartData, setBusinessValueChartData] = useState(
    {
      series: [
        {
          name: 'Valor médio do negócio',
          data: [],
        },
      ],
      options: {
        chart: {
          type: 'bar',
          toolbar: {
            show: false
          },
        },
        tooltip: {
          style: {
            fontSize: "10px",
            fontFamily: "Plus Jakarta Display",
          },
          onDatasetHover: {
            style: {
              fontSize: "10px",
              fontFamily: "Plus Jakarta Display",
            },
          },
          theme: "dark",
        },
        xaxis: {
          categories: [],
          show: true,
          labels: {
            show: true,
            style: {
              colors: "#fff",
              fontSize: "10px",
            },
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          show: true,
          color: "#fff",
          labels: {
            show: true,
            formatter: function (val) {
              return "R$" + abbreviateNumbers(val / 100);
            },
            style: {
              colors: "#fff",
              fontSize: "10px",
              fontFamily: "Plus Jakarta Display",
            },
          },
        },
        grid: {
          strokeDashArray: 10,
          borderColor: "#56577A",
          yaxis: {
            lines: {
              show: true,
            },
          },
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        colors: ['#44956B', '#D85308', '#D85308', '#D85308', '#D85308', '#D85308'],
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            borderRadius: 5,
            columnWidth: "12px",
            distributed: true,
            borderRadiusApplication: "end",
          },
        },
        legend: {
          show: false
        },
        responsive: [
          {
            breakpoint: 768,
            options: {
              plotOptions: {
                bar: {
                  borderRadius: 5,
                },
              },
            },
          },
        ],
      }
    }
  );
  const [businessConversionChartData, setBusinessConversionChartData] = useState(
    {
      series: [
        {
          name: '',
          data: []
        },
      ],
      options: {
        chart: {
          type: 'bar',
          stacked: true,
          toolbar: {
            show: false
          },
        },
        responsive: [{
          breakpoint: 480,
          options: {
            plotOptions: {
              bar: {
                horizontal: false,
                borderRadius: 5,
                borderRadiusApplication: 'end', // 'around', 'end'
                borderRadiusWhenStacked: 'last', // 'all', 'last'
              },
            },
          }
        }],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
            borderRadiusApplication: 'end', // 'around', 'end'
            borderRadiusWhenStacked: 'last', // 'all', 'last'
            dataLabels: {
              total: {
                enabled: false,
              }
            }
          },
        },
        dataLabels: {
          enabled: false,
          textAnchor: 'start',
          style: {
            colors: ['#fff']
          },
        },
        colors: ['#79BEF7', '#EEB954', '#BD7DC9', '#90A5AF', '#AED286', '#FCEC58', '#EE78A2', '#F3D3BD', '#49475B', '#E9EB9E', '#632A50', '#DB8A74', '#F7E1D7', '#00A878'],
        xaxis: {
          categories: [],
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: true,
            style: {
              colors: "#fff",
              fontSize: "10px",
            },
          },
        },
        legend: {
          position: 'bottom',
          labels: {
            colors: '#fff'
          },
          horizontalAlign: 'left',
          fontSize: '16px',
          itemMargin: {
            horizontal: 20,
            vertical: 0
          },
          markers: {
            radius: 10,
            width: 15,
            height: 15
          }
        },
        grid: {
          strokeDashArray: 10,
          borderColor: "#56577A",
          yaxis: {
            lines: {
              show: true,
            },
          },
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        yaxis: {
          show: true,
          color: "#fff",
          labels: {
            show: true,
            style: {
              colors: "#fff",
              fontSize: "10px",
              fontFamily: "Plus Jakarta Display",
            },
          },
        },
        tooltip: {
          style: {
            fontSize: "10px",
            fontFamily: "Plus Jakarta Display",
          },
          onDatasetHover: {
            style: {
              fontSize: "10px",
              fontFamily: "Plus Jakarta Display",
            },
          },
          theme: "dark",
        },
        fill: {
          opacity: 1
        }
      },
    }
  );
  const [filterDate, setFilterDate] = useState();

  const fetchData = async () => {
    const startDate = filterDate ? moment(filterDate[0], "DD-MM-YYYY HH:mm:ss").valueOf() : '';
    const endDate = filterDate ? moment(filterDate[1], "DD-MM-YYYY HH:mm:ss").valueOf() : '';
    const data = await getCommercialHomeGraphs(startDate, endDate);
    if (!data.message) {
      setBarChartData(prevGraph => ({
        series: [{
          data: data?.leadsByUser?.series?.data
        }
        ],
        options: {
          ...prevGraph.options,
          xaxis: {
            ...prevGraph.options.xaxis,
            categories: data?.leadsByUser?.categories
          }
        }
      }))
      setLeadsByUserChartData(prevGraph => ({
        series: [{
          ...prevGraph.series[0],
          data: data?.leadsConvertedByUser?.series?.data
        }
        ],
        options: {
          ...prevGraph.options,
          xaxis: {
            ...prevGraph.options.xaxis,
            categories: data?.leadsConvertedByUser?.categories
          }
        }
      }))
      setBusinessValueChartData(prevGraph => ({
        series: [{
          ...prevGraph.series[0],
          data: data?.medianValueByUser?.series?.data
        }
        ],
        options: {
          ...prevGraph.options,
          xaxis: {
            ...prevGraph.options.xaxis,
            categories: data?.medianValueByUser?.categories
          }
        }
      }))
      setBusinessConversionChartData(prevGraph => ({
        series: data?.businessConverted?.series,
        options: {
          ...prevGraph.options,
          xaxis: {
            ...prevGraph.options.xaxis,
            categories: data?.businessConverted?.categories
          }
        }
      }))
    }
  };

  useEffect(() => {
    fetchData();
  }, [filterDate]);

  const isGraphDataEmpty = (arr) => {
    if (arr?.length === 0) {
      return false
    };
    const anyValueGreaterThanZero = arr?.some(val => Number(val) > 0);
    return anyValueGreaterThanZero
  };

  return (
    <DashboardLayout>
      <DashboardNavbar customTitle='Comercial' />
      <S.MainContainer container spacing='18px'>
        <Grid item xs={12} lg={12} xl={12}>
          <Grid container item md={7.5} lg={7.5} xl={6.5}>
            <CurrentYearMonthDatepicker setDate={setFilterDate} allButton  />
          </Grid>
          <Grid container>
            <Grid item md={6} xl={4}>
              <Card>
                <p>Leads criados por usuários</p>
                <p className="subTitle">Este Mês</p>
                {isGraphDataEmpty(barChartData?.series[0]?.data)
                  ?
                  <S.GraphGrid>
                    <BarChart
                      key={barChartData?.series[0]?.data}
                      barChartData={barChartData?.series}
                      barChartOptions={barChartData?.options}
                    />
                  </S.GraphGrid>
                  :
                  <Grid container justifyContent='center' mt='120px' mb='120px'>
                    <p>Sem leads criados este mês</p>
                  </Grid>
                }
              </Card>
            </Grid>
            <Grid item md={6} xl={4}>
              <Card>
                <p>Conversão de Leads por usuários</p>
                <p className="subTitle">Este Mês</p>
                {isGraphDataEmpty(leadsByUserChartData?.series[0]?.data)
                  ?
                  <S.GraphGrid>
                    <BarChart
                      key={leadsByUserChartData?.series[0]?.data}
                      barChartData={leadsByUserChartData?.series}
                      barChartOptions={leadsByUserChartData?.options}
                    />
                  </S.GraphGrid>
                  :
                  <Grid container justifyContent='center' mt='120px' mb='120px'>
                    <p>Sem leads convertidos este mês</p>
                  </Grid>
                }
              </Card>
            </Grid>
            <Grid item md={6} xl={4}>
              <Card>
                <p>Valor médio do négocio por usuario</p>
                <p className="subTitle">Este Mês</p>
                {isGraphDataEmpty(businessValueChartData?.series[0]?.data)
                  ?
                  <S.GraphGrid>
                    <BarChart
                      key={businessValueChartData?.series[0]?.data}
                      barChartData={businessValueChartData?.series}
                      barChartOptions={businessValueChartData?.options}
                    />
                  </S.GraphGrid>
                  :
                  <Grid container justifyContent='center' mt='120px' mb='120px'>
                    <p>Sem vendas este mês</p>
                  </Grid>
                }
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={12} xl={12}>
          <Card>
            <p>Conversão de Negócios</p>
            <p className="subTitle">Este Ano</p>
            <S.BusinessConversionGrid>
              <BarChart
                key={businessConversionChartData?.series}
                barChartData={businessConversionChartData?.series}
                barChartOptions={businessConversionChartData?.options}
              />
            </S.BusinessConversionGrid>
          </Card>
        </Grid>
      </S.MainContainer>
    </DashboardLayout>
  )
}

export default Commercial