import { useEffect, useState, useContext } from 'react'
import * as S from "./style"
import DashboardLayout from "defaultComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "defaultComponents/Navbars/DashboardNavbar";
import { Grid } from '@mui/material';
import Card from "@mui/material/Card";
import VuiBox from "components/VuiBox";
import constructionMini from 'assets/constructionMini.png'
import filter from 'assets/icons/filter.svg'
import { IoEllipsisVertical } from "react-icons/io5";
import { MdPlace } from "react-icons/md";
import { VscCircleFilled } from "react-icons/vsc";
import { getAuditLog } from 'utils/requests/other';
import moment from 'moment-timezone';
import 'moment/locale/pt-br';
import placeholderImg from '../../assets/placeholder.png'
import Pagination from '@mui/material/Pagination';
import { TokenContext } from 'context/TokenContext';
import VuiAvatar from "components/VuiAvatar";
import { useFilePicker } from 'react-sage';
import { RiCameraFill } from 'react-icons/ri';
import { getUser } from 'utils/requests/auth';
import showToast from 'components/Toast/Toast';
import * as T from "components/Toast/style"
import Modal from 'components/Modal/Modal';
import ChangePassword from './ModalContent/ChangePassword';
import { listAllUserNames } from 'utils/requests/auth';
import FilterModal from 'components/Modal/FilterModal';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import { useDebounce } from 'use-debounce';
import { SearchBarContext } from 'context/SearchBarContext';
import { editUserPhoto } from 'utils/requests/auth';

const Avatar = ({ img, theme, children, size, ...rest }) => (
  <VuiAvatar
    src={img}
    size={size ?? 'xl'}
    {...rest}
    sx={({ borders: { borderWidth }, palette: { light }, functions: { rgba } }) => ({
      position: "relative",
      borderRadius: "25%",
      border: "3px solid #FC6009"
    })}
  >
    {children}
  </VuiAvatar>
)

const Settings = () => {

  moment.locale("pt-br")

  const [log, setLog] = useState();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(6);
  const [pagination, setPagination] = useState([]);
  const [postAvatar, setPostAvatar] = useState();
  const [previewUrl, setPreviewUrl] = useState();
  const [userInfo, setUserInfo] = useState();
  const [userNamesList, setUserNamesList] = useState([]);
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [filterValues, setFilterValues] = useState([]);
  const [filtersQuery, setFiltersQuery] = useState({ name: [], text: null });

  const { searchQuery } = useContext(SearchBarContext);

  const [debouncedSearch] = useDebounce(searchQuery, 600);
  const [debouncedFilters] = useDebounce(filtersQuery, 600);

  const { decodedToken } = useContext(TokenContext);
  const role = decodedToken?.type;

  const fetchUserInfo = async () => {
    const data = await getUser(decodedToken?.username ?? '');
    if (!data?.message) {
      setUserInfo(data);
    }
  };

  const fetchAllUserNames = async () => {
    const data = await listAllUserNames();
    if (!data.message) {
      setUserNamesList(data);
      const checkArr = Array.from({ length: data[0]?.length }, () => false);
      setFilterValues(checkArr)
    }
  };

  useEffect(() => {
    fetchUserInfo();
    fetchAllUserNames();
  }, []);

  useEffect(() => {
    setFiltersQuery((prevData) => ({ ...prevData, text: debouncedSearch ? debouncedSearch?.toUpperCase() : null }));
    setPage(1);
  }, [debouncedSearch]);

  const imageInput = useFilePicker({ maxFileSize: 1, resizeImage: true });

  useEffect(() => {
    const getDataUrls = async () => {
      if (imageInput.files && imageInput.files[0]) {
        setPostAvatar(imageInput.files[0]);
        const reader = new FileReader();
        reader.readAsDataURL(imageInput.files[0]);
        reader.onload = () => {
          setPreviewUrl(reader.result);
        };
      };
    };
    getDataUrls();
  }, [imageInput.files]);

  const fetchAudit = async () => {
    const data = await getAuditLog(page - 1, size, debouncedFilters);
    if (!data.message) {
      setLog(data);
    };
  };

  const changeAvatar = async () => {
    if (postAvatar) {
      await editUserPhoto({ username: decodedToken?.username , file: postAvatar }).then((res) => {
        if (!res.message) {
          showToast("Foto alterada com sucesso!", "success");
          fetchAudit();
        };
        if (res.message) {
          showToast("Falha ao alterar foto", "error");
          setPostAvatar();
          setPreviewUrl();
        };
      });
    };
  };

  useEffect(() => {
    changeAvatar();
  }, [postAvatar])


  const calculatePagination = () => {
    const roundUpArrSize = Math.ceil(log[1] / 6);
    const paginationArr = Array.from({ length: roundUpArrSize }, (value, index) => index)
    setPagination(paginationArr);
  };

  useEffect(() => {
    fetchAudit();
  }, [page, debouncedFilters]);

  useEffect(() => {
    if (log) calculatePagination();
  }, [log]);

  const colorizeFirstOcurranceString = (text, stringFound, COLORIZE_STRING_MAP) => {
    const color = COLORIZE_STRING_MAP[stringFound];
    const subStrings = text.split(stringFound);
    return subStrings.map((string, index) => {
      let shouldColorize = string.split(/\s+/).filter(Boolean).pop()
      shouldColorize = shouldColorize && shouldColorize.includes('um') && index == 0 ? true : false
      if (shouldColorize) {
        return <>{string} <span style={{ color: color }}>{stringFound}</span></>
      } else {
        return <>{string} <span>{stringFound}</span></>
      }
    })
  };

  const getFirstFoundColorizedString = (text, COLORIZED_STRINGS) => {
    for (const word of COLORIZED_STRINGS) {
      if (text.includes(word)) {
        return word;
      }
    }
    return null;
  };

  const renderColoredText = (text) => {
    const COLORIZE_STRING_MAP = {
      'Nova Despesa': '#D7007B',
      'Despesa': '#D7007B',
      'Nova Receita': '#65EFAD',
      'Novo Faturamento': '#65EFAD',
      'Faturamento': '#65EFAD'
    }
    const COLORIZED_STRING_KEYS = Object.keys(COLORIZE_STRING_MAP);
    const stringFound = getFirstFoundColorizedString(text, COLORIZED_STRING_KEYS);
    if (!stringFound) {
      return <p style={{ fontSize: '18px' }}>{text}</p>;
    }
    return <p style={{ fontSize: '18px' }}>{colorizeFirstOcurranceString(text, stringFound, COLORIZE_STRING_MAP)}</p>;
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  const getPhoto = () => {
    if (!userInfo?.user?.avatar) {
      return placeholderImg;
    };
    if (userInfo?.user?.avatar && !postAvatar) {
      return userInfo?.user?.avatar;
    };
    if (previewUrl) {
      return previewUrl;
    };
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const handleOnChange = (position) => {
    const updatedCheckedState = filterValues.map((item, index) =>
      index === position ? !item : item
    );
    setFilterValues(updatedCheckedState);
  };

  const tagFilter = () => {
    const checkedIndexes = filterValues?.map((bool, index) => bool === true ? index : null).filter((el) => (el !== null));
    const result = checkedIndexes?.map((index) => userNamesList[0][index].username);
    result.length !== filtersQuery?.name?.length ? setFiltersQuery((prevData) => ({ ...prevData, name: result })) : null;
  };

  useEffect(() => {
    tagFilter();
  }, [filterValues]);

  return (
    <DashboardLayout>
      <DashboardNavbar customTitle='Configurações' />
      <T.ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <VuiBox mt={4}>
        <VuiBox mb={1.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={7} xl={6}>
              <Grid container spacing={3}>
                <Grid item xs={12} xl={12} sx={{ height: "180px" }}>
                  <Card>
                    <Grid container>
                      <S.InfluencerPhoto container item xs={3} sm={2} md={1.5} lg={2} xl={2} xxl={1.5} justifyContent='center'>
                        <div>
                          <Avatar img={getPhoto()} />
                        </div>
                        <S.IndicatorGrid container onClick={imageInput.onClick}>
                          <S.PhotoIndicator>
                            <RiCameraFill size='20px' color='#fff' style={{ alignSelf: 'center' }} />
                            <imageInput.HiddenFileInput accept=".jpg, .jpeg, .png" />
                          </S.PhotoIndicator>
                        </S.IndicatorGrid>
                      </S.InfluencerPhoto>
                      <Grid item flexDirection='column' xs={9} md={9} xl={9} xxl={10.5} style={{ paddingLeft: "20px" }}>
                        <p style={{ fontSize: "22px" }}>{userInfo?.user?.name ?? ''}</p>
                        <p style={{ fontSize: "18px", opacity: "0.75" }}>{userInfo?.user?.email ?? ''}</p>
                        <p
                          style={{ fontSize: "14px", color: "#E12F31", fontWeight: 300, cursor: "pointer" }}
                          onClick={() => setOpenPasswordModal(true)}
                        >
                          Alterar senha
                        </p>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
                <Grid item xs={12} sx={{ height: "600px" }}>
                  <Card>
                    <p>Configurações da plataforma</p>
                    <Grid container justifyContent='center' sx={{ marginTop: "130px" }}>
                      <img src={constructionMini} draggable='false' />
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={5} xl={6} sx={{ height: "780px" }}>
              <Card sx={{ overflow: "auto", justifyContent: 'space-between' }}>
                <Grid container flexDirection='column' justifyContent='space-between'>
                  <Grid container justifyContent='space-between' sx={{ marginBottom: "10px" }}>
                    <p>Audit Log</p>
                    {role !== 'INFLUENCER' &&
                      <S.EditButton>
                        <img src={filter} draggable='false' onClick={() => setOpenFilter(true)} />
                      </S.EditButton>
                    }
                  </Grid>
                  <S.LogResultGrid container gap='24px'>
                    {!log &&
                      <p>Sem informações para exibir</p>
                    }
                    {log && log[0]?.map((entry) => (
                      <Grid container key={entry?.id}>
                        <S.LogPhoto item xs={2.5} md={3} lg={3.5} xl={2.75} xxl={1.75} sx={{ padding: "10px" }}>
                          <div>
                            <Avatar img={entry?.user?.avatar ? entry?.user?.avatar : placeholderImg} size='lg' />
                          </div>
                        </S.LogPhoto>
                        <Grid item flexDirection='column' xs={8.5} md={8} lg={6.25} xl={6.75} xxl={8.25}>
                          <p
                            style={{ fontSize: "18px", color: "#FC6009", fontWeight: 700, lineHeight: 1.3 }}
                          >
                            {entry?.user?.name}
                          </p>
                          {renderColoredText(entry?.action)}
                          <Grid container gap='10px'>
                            <Grid display='flex' item>
                              <MdPlace size='12px' style={{ alignSelf: "center" }} />
                              <p style={{ fontSize: "12px" }}>{entry?.local}</p>
                            </Grid>
                            <Grid display='flex' item>
                              <VscCircleFilled size='14px' style={{ alignSelf: "center" }} />
                              <p style={{ fontSize: "12px" }}>{moment().to(moment(entry?.createdAt).tz('America/Maceio').format())}</p>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container item justifyContent='flex-end' xs={1} md={1} xl={1} xxl={1}>
                          <IoEllipsisVertical size='20px' opacity='0.7' style={{ alignSelf: 'center' }} />
                        </Grid>
                      </Grid>
                    ))
                    }
                  </S.LogResultGrid>
                </Grid>
                <S.PaginationGrid container justifyContent='center' sx={{ marginTop: "20px" }}>
                  <Pagination count={pagination?.length} page={page} siblingCount={1} boundaryCount={1} onChange={handleChange} />
                </S.PaginationGrid>
              </Card>
            </Grid>
          </Grid>
        </VuiBox>
      </VuiBox>
      <Modal open={openPasswordModal} onClose={() => setOpenPasswordModal(false)} size='sm'>
        <ChangePassword closeModal={() => setOpenPasswordModal(false)} />
      </Modal>
      <FilterModal size='sm' open={openFilter} onClose={() => setOpenFilter(false)}>
        <p>Filtragem por usuário</p>
        {userNamesList && userNamesList[0]?.map((cat, index) => (
          <Grid container key={index}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={filterValues[index]}
              onChange={() => handleOnChange(index)}
              name={cat.name}
            />
            <p style={{ fontSize: "18px" }}>{cat.name}</p>
          </Grid>
        ))
        }
      </FilterModal>
    </DashboardLayout>
  )
}

export default Settings