import React, { useEffect } from 'react'

// @mui material components
import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import { Grid } from "@mui/material";
import { Link } from 'react-router-dom';

import arrowRightUp from "assets/icons/arrow-right-up.svg"

import * as S from './style'

// Billing page components
import Transaction from "layouts/billing/components/Transaction";
import CreditBalance from "layouts/billing/components/CreditBalance";
import moment from 'moment';
import 'moment/locale/pt-br';
import { jsPDF } from "jspdf";
import viuzzDoc from 'assets/doc.png'
import { getPdfData } from 'utils/requests/other';

import { TokenContext } from "context/TokenContext";

const Transactions = (props) => {
  const [pdfMenu, setPdfMenu] = React.useState(false);
  const [unixTimestamp, setUnixTimestamp] = React.useState(moment(Date.now()).endOf('month').subtract(15, 'days').valueOf());
  const [pdfData, setPdfData] = React.useState();
  const [openMenu, setOpenMenu] = React.useState([]);

  const { decodedToken } = React.useContext(TokenContext);
  const role = decodedToken?.type;

  const toggleOpenMenu = (index) => {
    const updatedMenu = [...openMenu];
    updatedMenu[index] = !updatedMenu[index];
    setOpenMenu(updatedMenu);
  };

  const fetchData = async () => {
    await getPdfData(props?.influencerInfo?.username, unixTimestamp).then(res => {
      if (!res?.message) {
        setPdfData(res);
      };
    });
  };

  useEffect(() => {
    if (props?.influencerInfo?.username) {
      fetchData();
    }
  }, [props.influencerInfo]);

  const callPdf = () => {
    if (pdfData) {
      getPdf(pdfData?.Dados);
    };
  }

  useEffect(() => {
    if (props?.influencerInfo?.username) {
      fetchData();
    }
    callPdf();
  }, [unixTimestamp]);

  const capitalize = (str) => {
    const newString = str.charAt(0).toUpperCase() + str.slice(1)
    return newString;
  };

  const getPdf = (data) => {
    if (data) {
      const createHeaders = (keys) => {
        const result = [];
        for (let i = 0; i < keys.length; i += 1) {
          result.push({
            id: keys[i],
            name: keys[i],
            prompt: keys[i],
            width: 65,
            align: "center",
            padding: 0
          });
        }
        return result;
      }

      const headers = createHeaders([
        "Descrição",
        "Valor",
      ]);

      var doc = new jsPDF({ putOnlyUsedFonts: true });
      doc.addImage(viuzzDoc, "PNG", 0, 0, 210, 300);
      doc.setFontSize(24)
      doc.text(pdfData?.Influenciador, 10, 50)
      doc.setFontSize(18)
      doc.text(capitalize(moment(unixTimestamp).format("MMMM, YYYY")), 20, 60)
      doc.table(30, 80, pdfData?.Dados, headers, { fontSize: 15 });
      doc.save('viuzz.pdf')
    }
  };

  const redirects = {
    ADMINISTRATOR: `/influencers/${props?.influencerInfo?.username}/financeiro`,
    H_COMERCIAL: `/financeiro/${props?.influencerInfo?.username}/financeiro`,
    INFLUENCER: '/financeiro'
  };

  return (
    <Card sx={{ height: "100%", flexDirection: "row", justifyContent: "space-between", overflow: "auto" }} onClick={() => { pdfMenu ? setPdfMenu(false) : null; openMenu.includes(true) ? setOpenMenu(openMenu.map((bool) => false)) : null }}>
      {!props.hideRedirect &&
        <S.UpdateButtonGrid>
          <Link to={redirects[role]}>
            <S.EditButton>
              <img src={arrowRightUp} />
            </S.EditButton>
          </Link>
        </S.UpdateButtonGrid>
      }
      <Grid item lg={5} xl={5}>
        <VuiBox
          display="flex"
          mb="18px"
          flexDirection="column"
          sx={({ breakpoints }) => ({
            [breakpoints.down("lg")]: {
              flexDirection: "column",
            },
          })}
        >
          <VuiTypography
            variant="lg"
            fontWeight="bold"
            textTransform="capitalize"
            color="white"
            sx={({ breakpoints }) => ({
              [breakpoints.only("sm")]: {
                mb: "6px",
              },
            })}
          >
            Financeiro
          </VuiTypography>
          <S.PerformanceGrid container>
            <p className={props?.data?.lastWeekChanges?.up ? 'positive' : 'negative'}>{props?.data?.lastWeekChanges?.up ? '+' : '-'}{props?.data?.lastWeekChanges?.percentage}%</p>
            <p className="date">na última semana</p>
          </S.PerformanceGrid>
        </VuiBox>
        <VuiBox>
          <VuiBox
            component="ul"
            display="flex"
            flexDirection="column"
            p={0}
            m={0}
            sx={{ listStyle: "none" }}
          >
            {props?.data?.financialEntries && props?.data?.financialEntries[0]?.slice(0, 5).map((entry) => (
              <Transaction
                key={entry.id}
                color=/* "success" */{entry.status === 'PENDENTE' ? "text" : ((entry.status === "PAGO" && (role !== "INFLUENCER" && entry.type === "TRANSFERENCIA")) ? "error" : "success")}
                icon=/* "arrow_downward" */{entry.status === 'PENDENTE' ? "pending" : ((entry.status === "PAGO" && (role !== "INFLUENCER" && entry.type === "TRANSFERENCIA")) ? "arrow_upward" : "arrow_downward")}
                name={entry.description ?? ''}
                description={entry.dateInvoiced ? moment(entry.dateInvoiced).format('LLL') : ''}
                value={`+ R$ ${Number((entry?.value / 100).toFixed(2)).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}
              />
            ))
            }
          </VuiBox>
          <VuiBox
            component="ul"
            display="flex"
            flexDirection="column"
            p={0}
            m={0}
            sx={{ listStyle: "none" }}
          >
          </VuiBox>
        </VuiBox>
      </Grid>
      <S.DividerGrid container item lg={1} xl={2}>
        <span style={{ width: "1px" }} />
      </S.DividerGrid>
      {!props?.hideCredit &&
        <Grid item lg={5} xl={5}>
          <CreditBalance
            data={props?.data}
            state={pdfMenu}
            setState={setPdfMenu}
            getPdf={callPdf}
            toggleOpenMenu={toggleOpenMenu}
            openMenu={openMenu}
            unixTimestamp={unixTimestamp}
            setUnixTimestamp={setUnixTimestamp}
            cashout={props?.cashout}
          />
        </Grid>
      }
    </Card>
  );
}

export default Transactions;
