import { useState, useEffect, useCallback, useContext } from 'react'
import DashboardLayout from "defaultComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "defaultComponents/Navbars/DashboardNavbar";
import * as S from './style'
import filter from 'assets/icons/filter.svg'
import MonthDatepicker from 'components/Datepicker/MonthDatepicker';
import { Grid, Card } from '@mui/material';
import Tabs from "@mui/material/Tabs";
import { MdFolderCopy } from "react-icons/md";
import { RiFileEditFill, RiTaskFill } from "react-icons/ri";
import breakpoints from "assets/theme/base/breakpoints";
import AddProject from '../ModalContent/AddProject';
import Modal from 'components/Modal/Modal';
import * as T from "components/Toast/style";
import Pagination from '@mui/material/Pagination';
import { useParams } from 'react-router-dom';
import { getProjects } from 'utils/requests/project';
import InfluencerProjectsTable from '../components/InfluencerProjectTable';
import moment from 'moment';
import 'moment/locale/pt-br';
import { TokenContext } from "context/TokenContext";

const ProjectListInfluencer = () => {
  moment.locale("pt-br");

  const [projects, setProjects] = useState();
  const [openAddProjectModal, setOpenAddProjectModal] = useState(false);
  const [filterDate, setFilterDate] = useState();
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState("");
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(8);
  const [pagination, setPagination] = useState([]);

  const { decodedToken } = useContext(TokenContext);
  const loggedUsername = decodedToken?.username;

  const params = useParams();

  const fetchProjects = useCallback(async (status) => {
    const startDate = filterDate ? moment(filterDate[0], "DD-MM-YYYY HH:mm:ss").valueOf() : '';
    const endDate = filterDate ? moment(filterDate[1], "DD-MM-YYYY HH:mm:ss").valueOf() : '';
    const data = await getProjects(
      page - 1,
      size,
      { username: params?.username ? [params?.username] : [loggedUsername] },
      startDate,
      endDate,
      status ?? []
    );
    if (!data.message) {
      setProjects(data);
    };
  }, [page, filterDate]);

  useEffect(() => {
    fetchProjects();
  }, [page]);

  const calculatePagination = () => {
    const roundUpArrSize = Math.ceil(projects[1] / 8);
    const paginationArr = Array.from({ length: roundUpArrSize }, (value, index) => index)
    setPagination(paginationArr);
  };

  useEffect(() => {
    if (projects) calculatePagination();
  }, [projects]);

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.md
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    if(page !== 1) setPage(1);
  }, [tabValue]);

  useEffect(() => {
    if (!tabValue) {
      fetchProjects();
      return
    };
    if (tabValue === 'NEGOTIATION') {
      fetchProjects(['LEADS', 'RELATIONSHIP', 'FOLLOW_UP', 'NEGOTIATION'])
      return
    };
    if (tabValue === 'ONGOING') {
      fetchProjects(['CLOSED_CONTRACT', 'ONGOING'])
      return
    };
    fetchProjects([tabValue]);
  }, [fetchProjects, tabValue]);

  return (
    <DashboardLayout>
      <T.ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <DashboardNavbar />
      <Grid>
        <Grid container item justifyContent='center'>
          <Tabs
            orientation={tabsOrientation}
            value={tabValue}
            onChange={handleSetTabValue}
            sx={{ background: "transparent", display: "flex", justifyContent: "flex-end" }}
          >
            <S.ListFilterTab label="Meus Projetos" value="" icon={<MdFolderCopy color="white" size="20px" />} />
            <S.ListFilterTab label="Em Negociação" value="NEGOTIATION" icon={<RiFileEditFill color="#5ACC93" size="20px" />} />
            <S.ListFilterTab label="Em Produção" value="ONGOING" icon={<RiFileEditFill color="#33CCFF" size="20px" />} />
            <S.ListFilterTab label="Finalizados" value="ENDED" icon={<RiTaskFill color="#CA0073" size="20px" />} />
          </Tabs>
        </Grid>
        <Card>
          <S.FiltersGrid container item sm={12} md={12} lg={12} xl={12} justifyContent='space-between'>
            <Grid container item md={7.5} lg={7.5} xl={6.5}>
              <MonthDatepicker setDate={setFilterDate} allButton />
            </Grid>
          </S.FiltersGrid>
          <Grid>
            <InfluencerProjectsTable projects={projects ? projects[0] : []} refetch={fetchProjects} />
          </Grid>
          <S.PaginationGrid container justifyContent='center'>
            <Pagination count={pagination?.length} page={page} siblingCount={1} boundaryCount={1} onChange={handleChange} />
          </S.PaginationGrid>
        </Card>
      </Grid>
      <Modal open={openAddProjectModal} onClose={() => setOpenAddProjectModal(false)}>
        <AddProject />
      </Modal>
    </DashboardLayout>
  )
}

export default ProjectListInfluencer