import { useState, useEffect } from 'react';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import ButtonCustom from "components/ButtonCustom/ButtonCustom";
import { Grid } from "@mui/material"
import * as S from '../style'
import { getSellers } from 'utils/requests/other';
import { getMarketingUsers } from 'utils/requests/other';

const MembersModal = ({ cardMembers, setMembers, closeModal, team }) => {
  const [memberOptions, setMemberOptions] = useState([]);
  const [newMembers, setNewMembers] = useState(cardMembers ?? [])

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const fetchMembers = async () => {
    const data = await getMarketingUsers(team);
    if (!data.message) {
      setMemberOptions(data);
    }
  };

  useEffect(() => {
    fetchMembers();
  }, []);

  useEffect(() => {
    setMembers([]);
  }, [team]);

  return (
    <Grid container flexDirection='column'>
      <p>Membros</p>
      <S.SelectInputGrid container item mt={2} pt='20px' pb='50px'>
        <Autocomplete
          disablePortal
          disableCloseOnSelect
          multiple
          value={newMembers ? newMembers : []}
          onChange={(e, value) => {
            setNewMembers(value)
          }}
          options={memberOptions ? memberOptions : []}
          sx={{ width: "100%" }}
          renderInput={(params) => <S.Input {...params} placeholder='Membros' />}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </li>
          )}
        />
      </S.SelectInputGrid>
      <ButtonCustom
        sx={{ maxWidth: '70px', height: '30px !important', alignSelf: 'flex-end' }}
        onClick={() => { closeModal(); setMembers('members', newMembers) }}
      >
        Salvar
      </ButtonCustom>
    </Grid>
  )
}

export default MembersModal