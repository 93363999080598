import { useState, useEffect } from 'react';
import { Grid, Autocomplete, InputAdornment, FormHelperText } from '@mui/material'
import placeholder from 'assets/leadsPlaceholder.png'
import { Formik, Form, useFormik } from 'formik';
import * as S from './style'
import * as Yup from "yup";
import { RiInstagramLine, RiYoutubeFill, RiTiktokFill, RiTwitterFill, RiSpotifyFill, RiAddFill, RiCameraFill } from "react-icons/ri"
import { useFilePicker } from 'react-sage';
import showToast from 'components/Toast/Toast';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';
import VuiAvatar from "components/VuiAvatar";
import { IoAlertCircleOutline } from 'react-icons/io5';
import { createLead } from 'utils/requests/leads';
import { branches, curves } from 'utils/utils';

const Avatar = ({ img, theme, children, ...rest }) => (
  <VuiAvatar
    src={img}
    size="xxl"
    {...rest}
    sx={({ borders: { borderWidth }, palette: { light }, functions: { rgba } }) => ({
      position: "relative",
    })}
  >
    {children}
  </VuiAvatar>
)

const CreateClients = ({ refetch, closeModal }) => {
  const [postAvatar, setPostAvatar] = useState();
  const [previewUrl, setPreviewUrl] = useState();
  const [validateFields, setValidateFields] = useState(false);

  const imageInput = useFilePicker({ maxFileSize: 1, resizeImage: true });

  useEffect(() => {
    const getDataUrls = async () => {
      if (imageInput.files && imageInput.files[0]) {
        setPostAvatar(imageInput.files[0]);
        const reader = new FileReader();
        reader.readAsDataURL(imageInput.files[0]);
        reader.onload = () => {
          setPreviewUrl(reader.result);
        };
      };
    };
    getDataUrls();
  }, [imageInput.files]);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: validateFields,
    validateOnBlur: false,

    initialValues: {
      businessName: '',
      businessDescription: '',
      branch: '',
      curve: '',
      instagram: '',
      youtube: '',
      tiktok: '',
      spotify: '',
      twitter: '',
      contacts: [],
    },
    validationSchema: Yup.object({
      businessName: Yup.string().required('Nome da empresa é obrigatório'),
      businessDescription: Yup.string().required('Descrição é obrigatório'),
      branch: Yup.string().required('Ramo é obrigatório'),
      curve: Yup.string().required('Curva é obrigatório'),
      instagram: Yup.string(),
      youtube: Yup.string(),
      tiktok: Yup.string(),
      spotify: Yup.string(),
      twitter: Yup.string(),
      contacts: Yup.array(),
    }),
    onSubmit: async (values) => {
      const clientValues = {
        businessName: values.businessName ? values.businessName : '',
        businessDescription: values.businessDescription ? values.businessDescription : '',
        branch: values.branch ? values.branch : '',
        curve: values.curve ? values.curve : '',
        instagram: values.instagram ? JSON.stringify({ username: values.instagram }) : '',
        spotify: values.spotify ? JSON.stringify({ username: values.spotify }) : '',
        tiktok: values.tiktok ? JSON.stringify({ username: values.tiktok }) : '',
        twitter: values.twitter ? JSON.stringify({ username: values.twitter }) : '',
        youtube: values.youtube ? JSON.stringify({ username: values.youtube }) : '',
        avatar: postAvatar ?? ''
      };

      Object.keys(clientValues).forEach(key => {
        if (clientValues[key] === '') {
          delete clientValues[key];
        };
      });

      Object.keys(clientValues).forEach(key => {
        if ((Array.isArray(clientValues[key])) && (clientValues[key].length === 0)) {
          delete clientValues[key];
        };
      });

      if (Object.keys(clientValues).length > 0) {
        await createLead(clientValues).then((res) => {
          if (res?.message) {
            showToast('Falha na criação de lead', 'error');
          };
          if (!res?.message) {
            showToast('Lead criado com sucesso!', 'success');
            refetch();
            closeModal();
          };
        });
      }
      if (Object.keys(clientValues).length === 0) showToast('Sem dados alterados, campos sem alterações', 'warning');
    }
  });

  return (
    <Grid container flexDirection='column'>
      <p>Criar Lead</p>
      <Grid container justifyContent='center'>
        <S.InfluencerPhoto>
          {/*           <div className='photoContainer'>
            <img src={previewUrl ?? placeholder} draggable='false' />
          </div> */}
          <div>
            <Avatar img={previewUrl ?? placeholder} />
          </div>
          <S.IndicatorGrid onClick={imageInput.onClick}>
            <S.BranchIndicator>
              <RiCameraFill size='20px' color='#fff' style={{ alignSelf: 'center' }} />
            </S.BranchIndicator>
            <imageInput.HiddenFileInput accept=".jpg, .jpeg, .png" />
          </S.IndicatorGrid>
        </S.InfluencerPhoto>
      </Grid>
      <Formik initialValues={formik.initialValues} validationSchema={formik.validationSchema} onSubmit={formik.handleSubmit}>
        <Form>
          <Grid container flexDirection='column' gap='15px' mt='20px'>
            <S.InputGrid>
              <S.Input
                name='businessName'
                id='businessName'
                placeholder='Nome do empresa'
                onChange={formik.handleChange}
                value={formik.values.businessName}
                error={Boolean(formik.errors.businessName)}
                helperText={formik.errors.businessName}
              />
            </S.InputGrid>
            <Grid container justifyContent='space-between'>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <Autocomplete
                  name='branch'
                  disablePortal
                  value={formik.values.branch ? formik.values.branch : null}
                  onChange={(e, value) => {
                    formik.setFieldTouched('branch')
                    formik.setFieldValue('branch', value ? value : '')
                  }}
                  options={branches ? branches : []}
                  sx={{ width: "100%" }}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => <S.Input {...params} placeholder='Ramo' />}
                  isOptionEqualToValue={(option, value) => option === value}
                />
                {(Boolean(formik.errors.branch) && formik.touched.branch) && <FormHelperText sx={{ color: "#f44336", marginLeft: "14px" }}>Ramo é um campo obrigatório</FormHelperText>}
              </S.InputGrid>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <Autocomplete
                  name='curve'
                  disablePortal
                  value={formik.values.curve ? formik.values.curve : null}
                  onChange={(e, value) => {
                    formik.setFieldTouched('curve')
                    formik.setFieldValue('curve', value ? value : '')
                  }}
                  options={curves ? curves : []}
                  sx={{ width: "100%" }}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => <S.Input {...params} placeholder='Curva' />}
                  isOptionEqualToValue={(option, value) => option === value}
                />
                {(Boolean(formik.errors.curve) && formik.touched.curve) && <FormHelperText sx={{ color: "#f44336", marginLeft: "14px" }}>Curva é um campo obrigatório</FormHelperText>}
              </S.InputGrid>
            </Grid>
            <S.InputGrid>
              <S.Input
                name='businessDescription'
                id='businessDescription'
                placeholder='Descrição'
                onChange={formik.handleChange}
                value={formik.values.businessDescription}
                error={Boolean(formik.errors.businessDescription)}
                helperText={formik.errors.businessDescription}
              />
            </S.InputGrid>
            <Grid container justifyContent='space-between'>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  name='instagram'
                  hiddenLabel
                  type='text'
                  placeholder="Instagram"
                  fullWidth
                  data-input
                  autoComplete='off'
                  onChange={formik.handleChange}
                  value={formik.values.instagram}
                  error={Boolean(formik.errors.instagram)}
                  helperText={formik.errors.instagram}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <RiInstagramLine />
                      </InputAdornment>
                    )
                  }}
                />
              </S.InputGrid>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  name='youtube'
                  hiddenLabel
                  type='text'
                  placeholder="Youtube"
                  fullWidth
                  data-input
                  autoComplete='off'
                  onChange={formik.handleChange}
                  value={formik.values.youtube}
                  error={Boolean(formik.errors.youtube)}
                  helperText={formik.errors.youtube}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <RiYoutubeFill />
                      </InputAdornment>
                    )
                  }}
                />
              </S.InputGrid>
            </Grid>
            <Grid container justifyContent='space-between'>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  name='tiktok'
                  hiddenLabel
                  type='text'
                  placeholder="TikTok"
                  fullWidth
                  data-input
                  autoComplete='off'
                  onChange={formik.handleChange}
                  value={formik.values.tiktok}
                  error={Boolean(formik.errors.tiktok)}
                  helperText={formik.errors.tiktok}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <RiTiktokFill />
                      </InputAdornment>
                    )
                  }}
                />
              </S.InputGrid>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  name='spotify'
                  hiddenLabel
                  type='text'
                  placeholder="Spotify"
                  fullWidth
                  data-input
                  autoComplete='off'
                  onChange={formik.handleChange}
                  value={formik.values.spotify}
                  error={Boolean(formik.errors.spotify)}
                  helperText={formik.errors.spotify}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <RiSpotifyFill />
                      </InputAdornment>
                    )
                  }}
                />
              </S.InputGrid>
            </Grid>
            <Grid container justifyContent='space-between'>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  name='twitter'
                  hiddenLabel
                  type='text'
                  placeholder="Twitter"
                  fullWidth
                  data-input
                  autoComplete='off'
                  onChange={formik.handleChange}
                  value={formik.values.twitter}
                  error={Boolean(formik.errors.twitter)}
                  helperText={formik.errors.twitter}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <RiTwitterFill />
                      </InputAdornment>
                    )
                  }}
                />
              </S.InputGrid>
            </Grid>
          </Grid>
          <S.AlertGrid container gap='5px' mt='15px'>
            <IoAlertCircleOutline size='24px' color='red' style={{ alignSelf: "center" }} />
            <p className='bold'>Lembrete: </p>
            <p>Você poderá adicionar um contato após a criação da empresa.</p>
          </S.AlertGrid>
          <Grid container justifyContent='flex-end' mt='15px'>
            <ButtonCustom
              label='Salvar'
              type='submit'
              sx={{ width: "150px", height: "40px !important", fontSize: "18px", fontWeight: "500" }}
              onClick={() => setValidateFields(true)}
            />
          </Grid>
        </Form>
      </Formik>
    </Grid>
  )
}

export default CreateClients