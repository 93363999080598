import React from 'react'
import DashboardLayout from "defaultComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "defaultComponents/Navbars/DashboardNavbar";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiPagination from "components/VuiPagination";
import Table from "defaultComponents/Tables/Table";
import { Grid, Box } from '@mui/material';
import * as S from './style'
import { useNavigate, Link } from 'react-router-dom';
import { IoArrowUp, IoArrowDown, IoEllipse, IoArrowBack, IoArrowForward, IoEllipsisVertical } from 'react-icons/io5';
import { getUsers, getInfluencerTags, editUser } from 'utils/requests/auth';
import filter from 'assets/icons/filter.svg'
import FilterModal from 'components/Modal/FilterModal';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import { SearchBarContext } from 'context/SearchBarContext';
import { useDebounce } from "use-debounce";
import { abbreviateNumbers } from 'utils/utils';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { RiAddFill } from 'react-icons/ri';
import Modal from 'components/Modal/Modal';
/* import CreateInfluencer from './ModalContent/CreateInfluencer'; */
import { TokenContext } from "context/TokenContext";
import * as T from "components/Toast/style"
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';
import showToast from 'components/Toast/Toast';
import { deleteUser } from 'utils/requests/auth';
import VuiAvatar from 'components/VuiAvatar';
import CreateUser from './ModalContent/CreateUser';
import EditUser from './ModalContent/EditUser';
import moment from 'moment';
import 'moment/locale/pt-br';
import CreateInfluencer from 'pages/Influencers/ModalContent/CreateInfluencer';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#e0e0e0',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}));

const Avatar = ({ img, theme, children, ...rest }) => (
  <VuiAvatar
    src={img}
    size="md"
    {...rest}
    sx={({ borders: { borderWidth }, palette: { light }, functions: { rgba } }) => ({
      borderRadius: '15px',
      position: "relative",
    })}
  >
    {children}
  </VuiAvatar>
)

const UserList = () => {
  moment.locale("pt-br");
  const navigate = useNavigate();

  const userTypes = [
    {
      name: 'Administrador',
      id: 'ADMINISTRATOR'
    },
    {
      name: 'Comercial',
      id: 'COMERCIAL'
    },
    {
      name: 'Head Comercial',
      id: 'H_COMERCIAL'
    },
    {
      name: 'Marketing',
      id: 'MARKETING'
    },
    {
      name: 'Head de Marketing',
      id: 'H_MARKETING'
    },
  ];

  const [users, setUsers] = React.useState([]);
  const [pagination, setPagination] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [tags, setTags] = React.useState();
  const [openFilter, setOpenFilter] = React.useState(false);
  const [filterValues, setFilterValues] = React.useState([]);
  const [filters, setFilters] = React.useState({
    name: null,
    userTags: [],
    type: ["ADMINISTRATOR", "H_COMERCIAL", "COMERCIAL", "MARKETING", "H_MARKETING"]
  });
  const [openCreateUserModal, setOpenCreateUserModal] = React.useState(false);
  const [openEditUserModal, setOpenEditUserModal] = React.useState(false);
  const [openMenu, setOpenMenu] = React.useState([]);
  const [openConfirmDeletionModal, setOpenConfirmDeletionModal] = React.useState(false);
  const [openConfirmStatusChangeModal, setOpenConfirmStatusChangeModal] = React.useState(false);
  const [chosenUser, setChosenUser] = React.useState();

  const { searchQuery } = React.useContext(SearchBarContext);
  const { decodedToken } = React.useContext(TokenContext);
  const role = decodedToken?.type;

  const [debouncedSearch] = useDebounce(searchQuery, 600);
  const [debouncedFilters] = useDebounce(filters, 600);

  const fetchTags = async () => {
    const data = await getInfluencerTags();
    if (!data.message) {
      setTags(data);
      const checkArr = Array.from({ length: userTypes.length }, () => true);
      setFilterValues(checkArr)
    }
  };

  const calculatePagination = () => {
    const roundUpArrSize = Math.ceil(users[1] / 10);
    const paginationArr = Array.from({ length: roundUpArrSize }, (value, index) => index)
    setPagination(paginationArr);
  };

  const fetchUsers = async () => {
    const data = await getUsers(page, filters);
    if (!data.message) {
      setUsers(data);
    };
  };

  React.useEffect(() => {
    fetchTags();
  }, []);

  React.useEffect(() => {
    fetchUsers();
  }, [page, debouncedFilters]);

  React.useEffect(() => {
    calculatePagination();
  }, [users]);

  React.useEffect(() => {
    setFilters((prevData) => ({ ...prevData, name: debouncedSearch ? debouncedSearch.toUpperCase() : null }));
    setPage(0);
  }, [debouncedSearch]);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const handleOnChange = (position) => {
    const updatedCheckedState = filterValues.map((item, index) =>
      index === position ? !item : item
    );
    setFilterValues(updatedCheckedState);
  };

  const tagFilter = () => {
    const checkedIndexes = filterValues?.map((bool, index) => bool === true ? index : null).filter((el) => (el !== null));
    const result = checkedIndexes?.map((index) => userTypes[index]?.id);
    if (result.length > 0) {
      result.length !== filters?.type?.length ? setFilters((prevData) => ({ ...prevData, type: result })) : null;
    } else {
      setFilters((prevData) => ({ ...prevData, type: ["ADMINISTRATOR", "H_COMERCIAL", "COMERCIAL", "MARKETING", "H_MARKETING"] }))
    };
    setPage(0);
  };

  React.useEffect(() => {
    tagFilter();
  }, [filterValues]);

  const toggleOpenMenu = (index) => {
    const updatedMenu = [...openMenu];
    updatedMenu[index] = !updatedMenu[index];
    setOpenMenu(updatedMenu);
  };

/*   const handleInfluencerDelete = (influencerInfo) => {
    setChosenInfluencer(influencerInfo);
    setOpenConfirmDeletionModal(true);
  }; */
  
/*   const cancelDelete = () => {
    setChosenUser();
    setOpenConfirmDeletionModal(false)
  }; */

  const handleInfluencerEdit = (influencerInfo) => {
    setChosenUser(influencerInfo);
    setOpenEditUserModal(true);
  };

  const cancelEdit = () => {
    setChosenUser();
    setOpenEditUserModal(false)
  };

  const handleInfluencerStatus = (influencerInfo) => {
    setChosenUser(influencerInfo);
    setOpenConfirmStatusChangeModal(true);
  };

  const cancelStatusChange = () => {
    setChosenUser();
    setOpenConfirmStatusChangeModal(false);
  };

  const userStatusChange = async (bool) => {
    await editUser(chosenUser?.username, { active: bool }).then((res) => {
      if (!res.message) {
        showToast(bool ? "Usuário ativado com sucesso" : "Usuário desativado com sucesso!", "success");
        fetchUsers();
        cancelStatusChange();
      };
      if (res.message) {
        showToast("Falha ao alterar dados", "error")
      };
    });
  };

  const handleUserDelete = async (username) => {
    await deleteUser(username).then((res) => {
      if (!res.message) {
        showToast("Usuário deletado com sucesso!", "success");
        fetchUsers();
        cancelDelete();
      };
      if (res.message) {
        showToast("Falha ao alterar dados", "error")
      };
    });
  };

  const userTypeTranslation = {
    ADMINISTRATOR: "Administrador",
    H_COMERCIAL: "Head Comercial",
    COMERCIAL: "Comercial",
    H_MARKETING: "Head de Marketing",
    MARKETING: "Marketing"
  };

  return (
    <DashboardLayout onClick={() => openMenu.includes(true) ? setOpenMenu(openMenu.map((bool) => false)) : null}>
      <DashboardNavbar />
      <T.ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <VuiBox py={1}>
        <VuiBox mb={1}>
          <S.TableCard>
            <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="22px">
              <VuiTypography variant="lg" color="white">
                Todos os Influencers
              </VuiTypography>
              <S.EditButton onClick={() => setOpenFilter(true)}>
                <img src={filter} draggable='false' />
              </S.EditButton>
            </VuiBox>
            <VuiBox>
              <Table
                mb={(role === 'ADMINISTRATOR') ? '40px' : 0}
                columns={[
                  { name: "nome", align: "left" },
                  { name: "tipo", align: "left" },
                  { name: "status", align: "center" },
                  { name: "último acesso", align: "center" },
                ]}
                rows={
                  users[0] && users[0].map((user, index) => {
                    return {
                      nome: (
                        <S.InfluencerGrid
                          container
                          flexDirection='row'
                          /* onClick={() => navigate(`/influencers/${user?.username ? user?.username : ""}`)} */
                          key={user?.id}
                          gap='28px'
                        >
                          <Grid display='flex' /* item xl={2} lg={3} md={2} */ >
                            <S.RankingPhoto>
                              <Avatar src={user?.avatar ? user?.avatar : ''} />
                            </S.RankingPhoto>
                          </Grid>
                          <S.UserTextGrid display='flex' flexDirection='column' /* item xl={8} lg={7} md={8} */>
                            <p className='name'>{user.name}</p>
                            <p>{user?.bio?.length > 60 ? user?.bio?.substring(0, 60) + "..." : user?.bio}</p>
                          </S.UserTextGrid>
                        </S.InfluencerGrid>
                      ),
                      tipo: (
                        <S.ThemeGrid container flexDirection='column'>
                          <p className='tags'>{userTypeTranslation[user?.type]}</p>
                          <p className='tagSubtitle'>
                            {
                              user?.userTags?.length > 0
                                ?
                                user?.userTags && user?.userTags?.join(", ")
                                :
                                '-'
                            }
                          </p>
                        </S.ThemeGrid>
                      ),
                      status: (
                        <S.StatusGrid container justifyContent='center'>
                          <IoEllipse color={user?.active ? '#65EFAD' : '#D7007B'} size='12px' />
                          <p className='status'>{user?.active ? "ACTIVE" : "INACTIVE"}</p>
                        </S.StatusGrid>
                      ),
                      "último acesso": (
                        <>
                          <Grid container justifyContent='space-between'>
                            <Grid container item xs={9} md={11} xl={11} justifyContent='center'>
                              <Grid container flexDirection='column'>
                                <p>{user?.lastActivity ? moment(user?.lastActivity).format('DD/MM/YYYY') : '-'}</p>
                                <p>{user?.lastActivity ? moment(user?.lastActivity).format('[às] HH:mm') : ''}</p>
                              </Grid>
                            </Grid>
                            {role === 'ADMINISTRATOR' &&
                              <Grid item xs={3} md={1} xl={1} sx={{ alignSelf: "center" }} onClick={() => toggleOpenMenu(index)}>
                                <IoEllipsisVertical size='20px' style={{ cursor: "pointer" }} />
                              </Grid>
                            }
                            {openMenu[index] &&
                              <S.OptionsContainer container justifyContent='flex-end'>
                                <S.OptionsGrid display='flex' flexDirection='column'>
                                  <p onClick={() => handleInfluencerEdit(user)}>Editar</p>
                                  <S.Divider />
                                  {user?.active &&
                                    <>
                                      <p onClick={() => handleInfluencerStatus(user)}>Desativar</p>
                                    </>
                                  }
                                  {!user?.active &&
                                    <>
                                      <p onClick={() => handleInfluencerStatus(user)}>Ativar</p>
                                    </>
                                  }
                                </S.OptionsGrid>
                              </S.OptionsContainer>
                            }
                          </Grid>
                        </>
                      ),
                    }
                  },)
                }
              />
            </VuiBox>
          </S.TableCard>
        </VuiBox>
      </VuiBox>
      <Grid container justifyContent='center'>
        <VuiPagination color='viuzzPag'>
          <VuiPagination item disabled={pagination.length <= 1} onClick={() => page > 0 ? setPage(page => page - 1) : null}>
            <IoArrowBack />
          </VuiPagination>
          {pagination.length > 1 && pagination.map((number) => (
            <VuiPagination item active={number === page} key={number} onClick={() => setPage(number)}>{number + 1}</VuiPagination>
          ))
          }
          {pagination.length <= 1 &&
            <VuiPagination item active>1</VuiPagination>
          }
          <VuiPagination item disabled={pagination.length <= 1} onClick={() => (page + 1) < pagination.length ? setPage(page => page + 1) : null}>
            <IoArrowForward />
          </VuiPagination>
        </VuiPagination>
      </Grid>
      {(role === "ADMINISTRATOR" || role === "H_COMERCIAL") &&
        <Box sx={{ '& > :not(style)': { m: 1 } }}>
          <S.ModalFab color="primary" onClick={() => setOpenCreateUserModal(true)}>
            <RiAddFill size='30px' />
          </S.ModalFab>
        </Box>
      }
      <FilterModal size='sm' open={openFilter} onClose={() => setOpenFilter(false)}>
        <p>Filtragem por tipo</p>
        {userTypes.map((cat, index) => (
          <Grid container key={index}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={filterValues[index]}
              onChange={() => handleOnChange(index)}
              name={cat.name}
            />
            <p style={{ fontSize: "18px" }}>{cat.name}</p>
          </Grid>
        ))
        }
      </FilterModal>
      <Modal open={openCreateUserModal} onClose={() => setOpenCreateUserModal(false)}>
        <CreateUser closeModal={() => setOpenCreateUserModal(false)} refetch={fetchUsers} />
      </Modal>
      <Modal open={openEditUserModal} onClose={cancelEdit}>
        <EditUser closeModal={() => setOpenEditUserModal(false)} refetch={fetchUsers} userInfo={chosenUser} />
      </Modal>
{/*       <Modal size='sm' open={openConfirmDeletionModal} onClose={() => setOpenConfirmDeletionModal(false)}>
        <Grid container flexDirection='column' gap='20px'>
          <p style={{ fontSize: "24px" }}>Confirmar exclusão</p>
          <p style={{ fontSize: "18px" }}>Deseja excluir essa entrada? Essa ação é irreversível</p>
          <Grid container gap='15px' justifyContent='center'>
            <Grid item lg={3}>
              <ButtonCustom label='Cancelar' onClick={() => cancelDelete()} sx={{ background: "linear-gradient(180deg, #818181 0%, #2a2020 155.15%)" }}></ButtonCustom>
            </Grid>
            <Grid item lg={3}>
              <ButtonCustom label='Confirmar' onClick={() => handleUserDelete(chosenUser?.username)}></ButtonCustom>
            </Grid>
          </Grid>
        </Grid>
      </Modal> */}
      <Modal size='sm' open={openConfirmStatusChangeModal} onClose={() => cancelStatusChange()}>
        <Grid container flexDirection='column' gap='20px'>
          <p style={{ fontSize: "24px" }}>Confirmar mudança de status</p>
          <p style={{ fontSize: "18px" }}>Deseja {chosenUser?.active ? 'DESATIVAR' : 'ATIVAR'} este usuário?</p>
          <Grid container gap='15px' justifyContent='center'>
            <Grid item lg={3}>
              <ButtonCustom label='Cancelar' onClick={() => cancelStatusChange()} sx={{ background: "linear-gradient(180deg, #818181 0%, #2a2020 155.15%)" }}></ButtonCustom>
            </Grid>
            <Grid item lg={3}>
              <ButtonCustom label='Confirmar' onClick={() => userStatusChange(!chosenUser?.active)}></ButtonCustom>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </DashboardLayout >
  )
}

export default UserList