import Modal from "components/Modal/Modal"
import { Grid } from "@mui/material"
import * as S from './style'
import placeholderImg from "assets/placeholder.png"
import ze from "assets/ze.png"
import VuiAvatar from "components/VuiAvatar";
import { IoAdd } from "react-icons/io5";
import { useState, useEffect, useContext } from "react";
import ButtonCustom from "components/ButtonCustom/ButtonCustom";
import {
  createCommercialCardComment,
  deleteCommercialCardComment,
  createCommercialCardAttachment,
  deleteCommercialCardAttachment,
} from "utils/requests/comercial";
import { IoIosArrowDown } from "react-icons/io";
import flatpickr from "flatpickr";
import moment from 'moment-timezone';
import 'moment/locale/pt-br';
import { Portuguese } from "flatpickr/dist/l10n/pt"
import confirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate"
import { Formik, Form, useFormik } from 'formik';
import * as Yup from "yup";
import TagsModal from "./components/TagsModal";
import MembersModal from "./components/MembersModal";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import showToast from "components/Toast/Toast";
import { TokenContext } from 'context/TokenContext';
import { Link } from "react-router-dom";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import MaskMoney from 'components/Masks/MaskMoney';
import { getSingleProject } from "utils/requests/project";
import { getSubCategories } from "utils/requests/financial";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';
import { createSimpleLead } from "utils/requests/leads";
import { branches, curves } from "utils/utils";
import { editBriefingProject } from "utils/requests/project";
import { getAllInfluencers } from "utils/requests/auth";
import * as T from "components/Toast/style"
import { getAllClients } from "utils/requests/leads"

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#e0e0e0',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}));

const Avatar = ({ img, theme, children, ...rest }) => (
  <VuiAvatar
    src={img}
    size="sm"
    {...rest}
    sx={({ borders: { borderWidth }, palette: { light }, functions: { rgba } }) => ({
      border: `${borderWidth[2]} solid ${rgba(light.focus, 0.3)}`,
      cursor: "pointer",
      position: "relative",
      "&:hover, &:focus": {
        zIndex: "10",
      },
    })}
  >
    {children}
  </VuiAvatar>
)

const CardModal = ({ cardId, refetchBoard, commercialId, closeModal }) => {
  const [addingAttachment, setAddingAttachment] = useState(false);
  const [writingComment, setWritingCommment] = useState(false);
  const [newComment, setNewComment] = useState('');
  const [openMembersModal, setOpenMembersModal] = useState(false);
  const [openTagModal, setOpenTagModal] = useState(false);
  const [cardData, setCardData] = useState();
  const [newAttachment, setNewAttachment] = useState('');
  const [titleEditable, setTitleEditable] = useState(false);
  const [openCommentDeleteModal, setOpenCommentDeleteModal] = useState(false);
  const [commentToDeleteId, setCommentToDeleteId] = useState('');
  const [openAttachmentDeleteModal, setOpenAttachmentDeleteModal] = useState(false);
  const [attachmentToDeleteId, setAttachmentToDeleteId] = useState('');
  const [imageStates, setImageStates] = useState([]);
  const [activeInfluencers, setActiveInfluencers] = useState();
  const [leadsArr, setLeadsArr] = useState();
  const [newLeadOpen, setNewLeadOpen] = useState(false);

  const { decodedToken } = useContext(TokenContext);
  const role = decodedToken?.type;

  const formik = useFormik({
    enableReinitialize: true,

    validateOnChange: true,
    validateOnBlur: false,

    initialValues: {
      title: cardData?.title ?? '',
      endDate: cardData?.budget?.paymentDateExpected ?? '',
      paymentDate: cardData?.budget?.paymentDate ?? '',
      tags: cardData?.pipeline?.tags ?? [],
      description: cardData?.description ?? '',
      influencers: cardData?.influencers ?? [],
      lead: cardData?.pipeline?.leadsRelated,
      budget: cardData?.budget?.value ? (Number((cardData?.budget?.value / 100).toFixed(2)).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })) : '',
      members: cardData?.pipeline?.usersMembers ?? [],
      status: cardData?.budget?.status ?? ''
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Título é um campo obrigatório"),
      endDate: Yup.string(),
      paymentDate: Yup.string(),
      members: Yup.array().min(1, 'Necessário pelo menos um membro vinculado'),
      tags: Yup.array().notRequired(),
      description: Yup.string().notRequired(),
      influencers: Yup.array().min(1, 'Necessário pelo menos um influencer vinculado'),
      lead: Yup.object().required("Empresa é um campo obrigatório"),
      budget: Yup.string().notRequired(),
      status: Yup.string().required('Status é um campo obrigatório'),
    }),
    onSubmit: async (values) => {
      const cardInitialValues = formik.initialValues;

      const cleanValue = Number(values?.budget?.replace(/[a-zA-Z$]+|\./g, "").replace(/,/g, "."));

      const unixDate = (date) => {
        return Number(moment(date, 'YYYY/MM/DD HH:mm').valueOf());
      };

      const editCard = {
        title: values.title !== cardInitialValues.title ? values.title : '',
        influencers: values.influencers !== cardInitialValues.influencers ? values.influencers.map((influencer) => influencer?.id) : '',
        description: values.description !== cardInitialValues.description ? values.description : '',
        budget: {
          paymentDateMs: values.paymentDate !== cardInitialValues.paymentDate ? unixDate(values.paymentDate) : '',
          paymentDateExpectedMs: values.endDate !== cardInitialValues.endDate ? unixDate(values.endDate) : '',
          value: values.budget !== cardInitialValues.budget ? cleanValue * 100 : '',
          status: values.status !== cardInitialValues.status ? values?.status?.toUpperCase() : '',
        },
        pipeline: {
          leadsRelated: values.lead.id !== cardInitialValues.lead.id ? values.lead.id : '',
          tags: values.tags !== cardInitialValues.tags ? values.tags : '',
          usersMembers: values.members !== cardInitialValues.members ? values.members.map((member) => member.id) : '',
        }
      };
      Object.keys(editCard).forEach(key => {
        if (typeof editCard[key] === 'object' && Object.keys(editCard[key]).length > 0) {
          Object.entries(editCard[key]).forEach(entry => {
            if (entry[1] === '') {
              delete editCard[key][entry[0]];
            }
          })
        }
        if (typeof editCard[key] === 'object' && Object.keys(editCard[key]).length === 0) {
          delete editCard[key];
        };
        if (editCard[key] === '') {
          delete editCard[key];
        };
      });
      if (Object.keys(editCard).length > 0) {
        await editBriefingProject(cardId, editCard).then((res) => {
          if (res.id) {
            showToast("Card editado com sucesso!", "success");
            fetchCardData();
            refetchBoard();
            closeModal();
          } else {
            showToast("Falha na edição do card", "error");
          };
        });
      };
      if (Object.keys(editCard).length === 0) showToast('Sem dados alterados, campos sem alterações', 'warning');
    }
  });

  const leadFormik = useFormik({
    enableReinitialize: true,

    initialValues: {
      businessName: '',
      businessDescription: '',
      branch: '',
      curve: '',
    },

    validationSchema: Yup.object({
      businessName: Yup.string().required('Nome da empresa é obrigatório'),
      businessDescription: Yup.string().required('Descrição é obrigatório'),
      branch: Yup.string().required('Ramo é obrigatório'),
      curve: Yup.string().required('Curva é obrigatório'),
    }),
    onSubmit: async (values) => {
      const clientValues = {
        businessName: values.businessName ? values.businessName : '',
        businessDescription: values.businessDescription ? values.businessDescription : '',
        branch: values.branch ? values.branch : '',
        curve: values.curve ? values.curve : '',
      };

      Object.keys(clientValues).forEach(key => {
        if (clientValues[key] === '') {
          delete clientValues[key];
        };
      });

      if (Object.keys(clientValues).length > 0) {
        await createSimpleLead(clientValues).then((res) => {
          if (res?.message) {
            showToast('Falha na criação de empresa', 'error');
          };
          if (!res?.message) {
            showToast('Empresa criada com sucesso!', 'success');
            setNewLeadOpen(false);
            leadFormik.resetForm();
            fetchLeads();
            formik.setFieldValue('lead', res);
          };
        });
      }
      if (Object.keys(clientValues).length === 0) showToast('Sem dados alterados', 'warning');

    }
  });

  const getActiveInfluencers = async () => {
    const data = await getAllInfluencers({ onlyActive: true });
    if (!data.message) {
      setActiveInfluencers(data);
    };
  };

  const fetchCardData = async () => {
    const data = await getSingleProject(cardId);
    if (!data.message) {
      setCardData(data);
      if (data.endDate) {
        formik.setFieldValue('endDate', data.endDate);
      }
    }
  };

  const fetchLeads = async () => {
    const data = await getAllClients();
    if (!data.message) {
      setLeadsArr(data);
    };
  };

  useEffect(() => {
    if (!cardData) fetchCardData();
    getActiveInfluencers();
    fetchLeads();
  }, []);

  flatpickr(".endDatePicker", {
    onClose: (selectedDates, dateStr) => {
      formik.setFieldValue('endDate', dateStr);
    },
    mode: "single",
    maxDate: new Date().fp_incr(365),
    locale: Portuguese,
    enableTime: true,
    plugins: [new confirmDatePlugin({})],
    disableMobile: true
  })

  flatpickr(".paymentDatePicker", {
    onClose: (selectedDates, dateStr, fp) => {
      formik.setFieldValue('paymentDate', dateStr);
    },
    mode: "single",
    maxDate: new Date().fp_incr(365),
    locale: Portuguese,
    enableTime: true,
    plugins: [new confirmDatePlugin({})],
    disableMobile: true
  })

  const actualTime = new Date().getTime();
  const timeComparison = moment(formik.values.endDate, 'YYYY-MM-DD HH:mm').valueOf() - actualTime;

  const statusTranslation = {
    LEADS: 'Leads 🔥',
    RELATIONSHIP: 'Relacionamento 🎯',
    FOLLOW_UP: 'FUP - Leads 🆙',
    NEGOTIATION: 'Em Negociação 💰',
    CLOSED_CONTRACT: 'Contrato Fechado 🤝',
    APPROVED: 'Aprovados',
    REJECTED: 'Rejeitados'
  };

  const isImgUrl = (url) => {
    const img = new Image();
    img.src = url;
    return new Promise((resolve) => {
      img.onerror = () => resolve(ze)
      img.onload = () => resolve(url)
    });
  };

  const createComment = async () => {
    if (!newComment) {
      showToast('Comentário não pode ser vazio!', 'error');
      return
    }
    await createCommercialCardComment({
      text: newComment,
      project: cardId
    })
      .then((res) => {
        if (res.id) {
          fetchCardData();
          setNewComment('');
          setWritingCommment(false);
        };
      });
  };

  const deleteComment = async (id) => {
    await deleteCommercialCardComment(id).then((res) => {
      if (res.text) {
        showToast("Comentário deletado com sucesso!", "success");
        fetchCardData();
        setOpenCommentDeleteModal(false);
        setCommentToDeleteId('');
      } else {
        showToast("Falha ao deletar comentário", "error");
      };
    });
  };

  const createAttachment = async () => {
    if (!newAttachment.title) {
      showToast("Não é possível adicionar nome de anexo vazio", "error");
      return
    }
    if (!newAttachment.link) {
      showToast("Não é possível adicionar link de anexo vazio", "error");
      return
    }
    await createCommercialCardAttachment({
      link: newAttachment?.link,
      title: newAttachment?.title,
      project: cardId
    })
      .then((res) => {
        if (res.id) {
          fetchCardData();
          setNewAttachment();
          setAddingAttachment(false);
        };
      });
  };

  const deleteAttachment = async (id) => {
    await deleteCommercialCardAttachment(id).then((res) => {
      if (res.link) {
        showToast("Anexo deletado com sucesso!", "success");
        fetchCardData();
        setOpenAttachmentDeleteModal(false);
        setAttachmentToDeleteId('');
      } else {
        showToast("Falha ao deletar anexo", "error");
      };
    });
  };

  useEffect(() => {
    const fetchImageStates = async () => {
      const states = cardData?.pipeline?.attachments ? await Promise.all(cardData?.pipeline?.attachments.map(url => isImgUrl(url.link))) : [];
      setImageStates(states);
    };

    fetchImageStates();
  }, [cardData]);

  const urlCheck = (url) => {
    const haveProtocol = url.includes('https://') || url.includes('http://');
    return haveProtocol ? url : `https://${url}`
  };

  useEffect(() => {
    if (formik?.values?.lead?.id === 'Criar nova empresa +') {
      setNewLeadOpen(true);
    };
  }, [formik.values.lead]);

  const handleNewLeadClose = () => {
    setNewLeadOpen(false);
    formik.setFieldValue('lead', null);
    leadFormik.resetForm();
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const filter = createFilterOptions();

  const fullRefetch = () => {
    refetchBoard();
    fetchCardData();
  };

  return (
    <>
      <T.ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <S.MainContainer container flexDirection='column' gap='10px'>
        <Formik
          initialValues={formik.initialValues}
          validationSchema={formik.validationSchema}
          onSubmit={formik.handleSubmit}
        >
          <Form>
            <S.CardTitleGrid container flexDirection='column'>
              {!titleEditable &&
                <p onClick={() => setTitleEditable(true)}>{formik.values.title}</p>
              }
              {titleEditable &&
                <S.TitleInputGrid container flexDirection='column' onBlur={() => setTitleEditable(false)}>
                  <S.Input
                    placeholder='Título'
                    name='title'
                    id='title'
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    multiline
                    onKeyDown={(e) => e.key === 'Enter' ? setTitleEditable(false) : ''}
                    onFocus={(e) =>
                      e.currentTarget.setSelectionRange(
                        e.currentTarget.value.length,
                        e.currentTarget.value.length
                      )}
                    autoFocus
                  />
                </S.TitleInputGrid>
              }
              {(Boolean(formik.errors.title)) && <FormHelperText sx={{ color: "#f44336", marginLeft: "14px" }}>{formik.errors.title}</FormHelperText>}
              <p className="subTitle">na lista {statusTranslation[cardData?.status]}</p>
            </S.CardTitleGrid>
            <Grid container justifyContent='space-between'>
              <Grid container item xs={12} md={6} xl={6} flexDirection='column' mb='10px'>
                <p className="dueDate">Data de Fechamento esperada</p>
                <S.DueDate className="endDatePicker">
                  <p>
                    {
                      formik.values.endDate
                        ?
                        moment(formik.values.endDate).tz('America/Maceio').format('DD [de] MMM [às] HH:mm')
                        :
                        "-"
                    }
                  </p>
                  <IoIosArrowDown size='12px' style={{ alignSelf: "center" }} />
                </S.DueDate>
              </Grid>
              <Grid container item xs={12} md={6} xl={6} flexDirection='column' mb='10px'>
                <p className="dueDate">Data de Pagamento</p>
                <S.DueDate className="paymentDatePicker">
                  <p>
                    {
                      formik.values.paymentDate
                        ?
                        moment(formik.values.paymentDate).tz('America/Maceio').format('DD [de] MMM [às] HH:mm')
                        :
                        "-"
                    }
                  </p>
                  {/*                   {timeComparison > 0 && timeComparison <= 86400000 &&
                    <S.DateAlertFlag>
                      <p>Entregar em breve</p>
                    </S.DateAlertFlag>
                  }
                  {timeComparison < 0 &&
                    <S.DateAlertExpireFlag>
                      <p>Em atraso</p>
                    </S.DateAlertExpireFlag>
                  } */}
                  <IoIosArrowDown size='12px' style={{ alignSelf: "center" }} />
                </S.DueDate>
              </Grid>
            </Grid>
            <S.CardDetails container>
              <Grid container item lg={6} flexDirection='column'>
                <p>Membros</p>
                <Grid container mt='5px'>
                  {formik.values.members.map((member) => (
                    <LightTooltip title={member?.name} key={member?.id}>
                      <div>
                        <Avatar img={member?.avatar ?? placeholderImg} />
                      </div>
                    </LightTooltip>
                  ))
                  }
                  {role !== 'COMERCIAL' &&
                    <LightTooltip title='Adicionar membro'>
                      <div>
                        <Avatar onClick={() => setOpenMembersModal(true)}>
                          <IoAdd />
                        </Avatar>
                      </div>
                    </LightTooltip>
                  }
                </Grid>
                {(Boolean(formik.errors.members)) && <FormHelperText sx={{ color: "#f44336", marginLeft: "14px" }}>{formik.errors.members}</FormHelperText>}
              </Grid>
              <Grid container item lg={6} flexDirection='column'>
                <p>Etiquetas</p>
                <Grid container mt='5px'>
                  {formik?.values?.tags.map((tag, index) => (
                    <S.Tag key={index} style={{ backgroundColor: tag?.bgcolor }}>{tag?.title}</S.Tag>
                  ))
                  }
                  <LightTooltip title='Adicionar etiqueta'>
                    <div>
                      <Avatar onClick={() => setOpenTagModal(true)}>
                        <IoAdd />
                      </Avatar>
                    </div>
                  </LightTooltip>
                </Grid>
              </Grid>
            </S.CardDetails>
            <S.Divider />
            <Grid container flexDirection='column' gap='20px' my='15px'>
              <Grid container>
                <S.InputGrid item md={12} xl={12}>
                  <S.Input
                    hiddenLabel
                    id='description'
                    name='description'
                    type='text'
                    placeholder="Descrição"
                    fullWidth
                    onChange={formik.handleChange}
                    value={formik.values.description}
                  />
                </S.InputGrid>
              </Grid>
              <Grid container justifyContent='space-between'>
                <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                  <Autocomplete
                    id='lead'
                    disablePortal
                    value={formik.values?.lead ? formik.values?.lead : null}
                    onChange={(e, value) => {
                      formik.setFieldValue('lead', value)
                    }}
                    options={leadsArr ? leadsArr[0] : []}
                    sx={{ width: "100%" }}
                    getOptionLabel={(option) => option.businessName}
                    renderInput={(params) => <S.Input {...params} placeholder='Empresa' />}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      filtered.push({
                        businessName: "Criar nova empresa +", id: "Criar nova empresa +"
                      });

                      return filtered;
                    }}
                  />
                  {(Boolean(formik.errors.lead)) && <FormHelperText sx={{ color: "#f44336", marginLeft: "14px" }}>{formik.errors.lead}</FormHelperText>}
                </S.InputGrid>
                <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                  <Autocomplete
                    id="influencers"
                    name="influencers"
                    disablePortal
                    disableCloseOnSelect
                    multiple
                    value={formik.values.influencers ? formik.values.influencers : []}
                    onChange={(e, value) => {
                      formik.setFieldValue('influencers', value)
                    }}
                    options={activeInfluencers ? activeInfluencers[0] : []}
                    sx={{ width: "100%" }}
                    renderInput={(params) => <S.Input {...params} placeholder='Influencers' />}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.name}
                      </li>
                    )}
                  />
                  {(Boolean(formik.errors.influencers)) && <FormHelperText sx={{ color: "#f44336", marginLeft: "14px" }}>{formik.errors.influencers}</FormHelperText>}
                </S.InputGrid>
              </Grid>
              <Grid container justifyContent='space-between'>
                <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                  <Autocomplete
                    disablePortal
                    id='status'
                    value={formik.values?.status ? formik.values?.status : null}
                    onChange={(e, value) => {
                      formik.setFieldValue('status', value)
                    }}
                    options={['PAGO', 'PENDENTE']}
                    sx={{ width: "100%" }}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => <S.Input {...params} placeholder='Status do pagamento' />}
                    isOptionEqualToValue={(option, value) => option === value}
                  />
                  {Boolean(formik.errors.status) && <FormHelperText sx={{ color: "#f44336", marginLeft: "14px" }}>Status é um campo obrigatório</FormHelperText>}
                </S.InputGrid>
                <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                  <S.Input
                    name='budget'
                    id='budget'
                    hiddenLabel
                    placeholder={"Valor(R$)"}
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.budget}
                    InputProps={{ inputComponent: MaskMoney }}
                    error={Boolean(formik.errors.budget) && formik.touched.budget}
                    helperText={formik.touched.budget ? formik.errors.budget : false}
                  />
                </S.InputGrid>
              </Grid>
            </Grid>
            <S.Divider />
            <Grid container flexDirection='column' mt='10px' mb='10px'>
              <Grid container justifyContent='space-between'>
                <p className="columnTitle">Anexos</p>
                <ButtonCustom onClick={() => setAddingAttachment(true)} sx={{ maxWidth: '90px', height: '30px !important' }}>Adicionar</ButtonCustom>
              </Grid>
              {addingAttachment &&
                <Grid container flexDirection='column' mt='10px'>
                  <S.MultilineInputGrid container item>
                    <S.Input
                      id="newAttachment"
                      placeholder='Inserir nome do anexo'
                      value={newAttachment?.title ?? ''}
                      onChange={(e) => setNewAttachment((prevData) => ({ ...prevData, title: e.target.value }))}
                      multiline
                      onFocus={(e) =>
                        e.currentTarget.setSelectionRange(
                          e.currentTarget.value.length,
                          e.currentTarget.value.length
                        )}
                      autoFocus
                    />
                  </S.MultilineInputGrid>
                  <S.MultilineInputGrid container item>
                    <S.Input
                      id="attachmentLink"
                      placeholder='Inserir link do arquivo'
                      value={newAttachment?.link ?? ''}
                      onChange={(e) => setNewAttachment((prevData) => ({ ...prevData, link: e.target.value }))}
                      multiline
                    />
                  </S.MultilineInputGrid>
                  <Grid container justifyContent='flex-end' gap='10px'>
                    <ButtonCustom
                      id='createAttachment'
                      sx={{ maxWidth: '70px', height: '30px !important', alignSelf: 'flex-end' }}
                      onClick={() => createAttachment()}
                    >
                      Salvar
                    </ButtonCustom>
                    <ButtonCustom
                      id='cancelAttachment'
                      onClick={() => { setAddingAttachment(false); setNewAttachment() }}
                      sx={{
                        maxWidth: '70px',
                        height: '30px !important',
                        background: 'linear-gradient(180deg, #999999 0%, #999999 155.15%)'
                      }}
                    >
                      Cancelar
                    </ButtonCustom>
                  </Grid>
                </Grid>
              }
              <Grid container flexDirection='column' gap='10px'>
                {cardData?.pipeline?.attachments?.map((attachment, index) => (
                  <Grid container key={index}>
                    <Grid item md={2.25} lg={2.25}>
                      <Link to={urlCheck(attachment?.link)} target="_blank" style={{ color: '#fff' }}>
                        <Grid item>
                          <img src={imageStates[index]} width='120px' height='100px' style={{ objectFit: "contain" }} />
                        </Grid>
                      </Link>
                    </Grid>
                    <Grid container item flexDirection='column' md={6} lg={6}>
                      <p>{attachment?.title}</p>
                      <S.AttachmentDate>Adicionado: {moment(attachment?.createdAt).tz('America/Maceio').format('DD [de] MMM [às] HH:mm')}</S.AttachmentDate>
                      <S.DeleteText
                        onClick={() => { setAttachmentToDeleteId(attachment?.id); setOpenAttachmentDeleteModal(true) }}
                      >
                        Excluir
                      </S.DeleteText>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <S.Divider />
            <Grid container flexDirection='column'>
              <p className="columnTitle">Comentários</p>
              <Grid container>
                <Grid container mt='10px'>
                  <Grid container item lg={1} justifyContent='center' pt='3px'>
                    <Avatar img={decodedToken?.avatar ?? placeholderImg} />
                  </Grid>
                  <Grid container item lg={11} flexDirection='column'>
                    <S.MultilineInputGrid container item>
                      <S.Input
                        id="comment"
                        placeholder='Escrever um comentário...'
                        multiline
                        onFocus={() => setWritingCommment(true)}
                        onChange={(e) => setNewComment(e.target.value)}
                        value={newComment}
                      />
                    </S.MultilineInputGrid>
                    {writingComment &&
                      <ButtonCustom
                        id='saveComment'
                        onClick={createComment}            
                        sx={{ maxWidth: '70px', height: '30px !important', alignSelf: 'flex-end', marginBottom: '10px' }}
                      >
                        Salvar
                      </ButtonCustom>
                    }
                  </Grid>
                </Grid>
                <Grid container flexDirection='column' gap='10px'>
                  {cardData?.pipeline?.comments?.map((comment) =>  (
                    <Grid container key={comment?.id}>
                      <Grid container item lg={1} justifyContent='center'>
                        <Avatar img={comment?.user?.avatar ?? placeholderImg} />
                      </Grid>
                      <Grid container item lg={11} flexDirection='column'>
                        <Grid container gap='5px'>
                          <p>{comment?.user?.name}</p>
                          <S.CommentTimestamp>
                            {moment(comment?.createdAt).tz('America/Maceio').format('DD [de] MMM [às] HH:mm')}
                          </S.CommentTimestamp>
                        </Grid>
                        <p>{comment?.text}</p>
                        {((role === 'ADMINISTRATOR') || (role === 'H_COMERCIAL') || (decodedToken?.id === comment?.user?.id)) &&
                          <S.DeleteText
                            onClick={() => { setCommentToDeleteId(comment?.id); setOpenCommentDeleteModal(true) }}
                          >
                            Excluir
                          </S.DeleteText>
                        }
                      </Grid>
                    </Grid>
                  ))
                  }
                </Grid>
              </Grid>
            </Grid>
            <Grid container justifyContent='flex-end'>
              <ButtonCustom
                sx={{ maxWidth: '150px', height: '30px !important' }}
                type='submit'
                id='saveChanges'
              >
                Salvar alterações
              </ButtonCustom>
            </Grid>
          </Form>
        </Formik>
      </S.MainContainer >
      <Modal size='sm' open={openTagModal} onClose={() => setOpenTagModal(false)}>
        <TagsModal
          cardTags={formik.values.tags}
          setTags={formik.setFieldValue}
          refetch={fullRefetch}
          closeModal={() => setOpenTagModal(false)}
        />
      </Modal>
      <Modal size='sm' open={openMembersModal} onClose={() => setOpenMembersModal(false)}>
        <MembersModal
          cardMembers={formik.values.members}
          setMembers={formik.setFieldValue}
          closeModal={() => setOpenMembersModal(false)}
          commercialId={commercialId}
        />
      </Modal>
      <Modal size='sm' open={openCommentDeleteModal} onClose={() => setOpenCommentDeleteModal(false)}>
        <Grid container flexDirection='column' gap='20px'>
          <p style={{ fontSize: "24px" }}>Confirmar exclusão</p>
          <p style={{ fontSize: "18px" }}>Deseja excluir esse comentário? Essa ação é irreversível</p>
          <Grid container gap='15px' justifyContent='center'>
            <Grid item lg={3}>
              <ButtonCustom
                label='Cancelar'
                onClick={() => setOpenCommentDeleteModal(false)}
                sx={{ background: "linear-gradient(180deg, #818181 0%, #2a2020 155.15%)" }}
              />
            </Grid>
            <Grid item lg={3}>
              <ButtonCustom label='Confirmar' onClick={() => deleteComment(commentToDeleteId)} />
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      <Modal size='sm' open={openAttachmentDeleteModal} onClose={() => setOpenAttachmentDeleteModal(false)}>
        <Grid container flexDirection='column' gap='20px'>
          <p style={{ fontSize: "24px" }}>Confirmar exclusão</p>
          <p style={{ fontSize: "18px" }}>Deseja excluir esse comentário? Essa ação é irreversível</p>
          <Grid container gap='15px' justifyContent='center'>
            <Grid item lg={3}>
              <ButtonCustom
                label='Cancelar'
                onClick={() => setOpenAttachmentDeleteModal(false)}
                sx={{ background: "linear-gradient(180deg, #818181 0%, #2a2020 155.15%)" }}
              />
            </Grid>
            <Grid item lg={3}>
              <ButtonCustom label='Confirmar' onClick={() => deleteAttachment(attachmentToDeleteId)} />
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      <Modal
        open={newLeadOpen}
        onClose={handleNewLeadClose}
        size={"sm"}
      >
        <Grid container flexDirection='column' gap='30px'>
          <p>Nova Empresa</p>
          <Formik initialValues={leadFormik.initialValues} validationSchema={leadFormik.validationSchema} onSubmit={leadFormik.handleSubmit}>
            <Form style={{ width: '100%' }}>
              <Grid container flexDirection='column' gap='15px'>
                <S.InputGrid item xl={12}>
                  <S.Input
                    fullWidth
                    name='businessName'
                    id='businessName'
                    placeholder='Nome da empresa'
                    onChange={leadFormik.handleChange}
                    value={leadFormik.values.businessName}
                    error={(Boolean(leadFormik.errors.businessName))}
                    helperText={leadFormik.errors.businessName}
                  />
                </S.InputGrid>
                <S.InputGrid item xs={12} md={12} xl={12}>
                  <Autocomplete
                    name='branch'
                    disablePortal
                    id='branch'
                    value={leadFormik.values.branch ? leadFormik.values.branch : null}
                    onChange={(e, value) => {
                      leadFormik.setFieldTouched('branch')
                      leadFormik.setFieldValue('branch', value ? value : '')
                    }}
                    options={branches ? branches : []}
                    sx={{ width: "100%" }}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => <S.Input {...params} placeholder='Ramo' />}
                    isOptionEqualToValue={(option, value) => option === value}
                  />
                  {(Boolean(leadFormik.errors.branch) && leadFormik.touched.branch) && <FormHelperText sx={{ color: "#f44336", marginLeft: "14px" }}>Ramo é um campo obrigatório</FormHelperText>}
                </S.InputGrid>
                <S.InputGrid item xs={12} md={12} xl={12}>
                  <Autocomplete
                    name='curve'
                    id='curve'
                    disablePortal
                    value={leadFormik.values.curve ? leadFormik.values.curve : null}
                    onChange={(e, value) => {
                      leadFormik.setFieldTouched('curve')
                      leadFormik.setFieldValue('curve', value ? value : '')
                    }}
                    options={curves ? curves : []}
                    sx={{ width: "100%" }}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => <S.Input {...params} placeholder='Curva' />}
                    isOptionEqualToValue={(option, value) => option === value}
                  />
                  {(Boolean(leadFormik.errors.curve) && leadFormik.touched.curve) && <FormHelperText sx={{ color: "#f44336", marginLeft: "14px" }}>Curva é um campo obrigatório</FormHelperText>}
                </S.InputGrid>
                <S.InputGrid item xs={12} md={12} xl={12}>
                  <S.Input
                    fullWidth
                    name='businessDescription'
                    id='businessDescription'
                    placeholder='Descrição'
                    onChange={leadFormik.handleChange}
                    value={leadFormik.values.businessDescription}
                    error={(Boolean(leadFormik.errors.businessDescription))}
                    helperText={leadFormik.errors.businessDescription}
                  />
                </S.InputGrid>
                <Grid container justifyContent='flex-end'>
                  <ButtonCustom data-cy label='Salvar' type='submit' sx={{ width: "100px", height: "40px !important", fontSize: "18px", fontWeight: "500" }} />
                </Grid>
              </Grid>
            </Form>
          </Formik>
        </Grid>
      </Modal>
    </>
  )
}

export default CardModal