import { useState, useEffect, useContext } from "react";
import DashboardLayout from "defaultComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "defaultComponents/Navbars/DashboardNavbar";
import VuiBox from "components/VuiBox";
import WelcomeMark from "layouts/dashboard/components/WelcomeMark";
import { Card, Grid } from "@mui/material";
import * as S from './style'
import LastProjects from "./components/Projects";
import { getProjectMarketingHomeData } from 'utils/requests/project';
import LineChart from "defaultComponents/Charts/LineCharts/LineChart";
import { IoArrowUp, IoArrowDown, IoEllipse } from "react-icons/io5";
import TimelineItem from "defaultComponents/Timeline/TimelineItem";
import { TokenContext } from "context/TokenContext";
import moment from 'moment';
import 'moment/locale/pt-br';
import Modal from "components/Modal/Modal";
import EventDetails from "pages/Calendar/ModalContent/EventDetails";
import Notifications from "layouts/dashboard/components/Notifications/Notifications";

const MarketingDashboard = () => {
  moment.locale("pt-br");

  const { decodedToken } = useContext(TokenContext);
  const role = decodedToken?.type;

  const [openEventDetails, setOpenEventDetails] = useState(false);
  const [eventToShow, setEventToShow] = useState();
  const [projectHomeData, setProjectHomeData] = useState();
  const [chartData, setChartData] = useState([]);
  const [chartOptions, setChartOptions] = useState({
    chart: {
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      theme: "dark",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
      categories: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],
      labels: {
        style: {
          colors: "#c8cfca",
          fontSize: "10px",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#c8cfca",
          fontSize: "10px",
        },
      },
    },
    legend: {
      show: false,
    },
    grid: {
      strokeDashArray: 5,
      borderColor: "#56577A",
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "vertical",
        shadeIntensity: 0,
        gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
        inverseColors: true,
        opacityFrom: 0.8,
        opacityTo: 0,
        stops: [],
      },
      colors: ["#FC6009", "#F8DF00", "#33CCFF"],
    },
    colors: ["#FC6009", "#F8DF00", "#33CCFF"],
  });
  const [showTooltip, setShowTooltip] = useState([false, false, false, false]);

  const fetchData = async () => {
    const data = await getProjectMarketingHomeData();
    if (!data.message) {
      setProjectHomeData(data);
      setChartData(data?.lastMonthProjects?.graphData?.data)
      setChartOptions((chartOptions) => ({
        ...chartOptions,
        xaxis: {
          ...chartOptions.xaxis,
          categories: data?.lastMonthProjects?.graphData?.labels
        }
      }));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const roleTranslation = {
    DESIGNER: ['Designers', 0],
    PRODUCER: ['Produção', 1],
    SOCIAL_MEDIA: ['Social Media', 2],
    FILMMAKER: ['Videomakers', 3]
  };

  const toggleShowTooltip = (index, bool) => {
    const updatedState = Array.from({ length: 4 }, () => false);
    updatedState[index] = bool;
    setShowTooltip(updatedState);
  };

  const ProductionStatusTooltip = ({ role }) => {
    return (
      <S.TooltipBase style={{ position: 'absolute', display: `${showTooltip[roleTranslation[role][1]] ? 'block' : 'none'}` }}>
        <div className="tooltip-title">
          {roleTranslation[role][0]}
        </div>
        <div className="tooltip-row">
          <div className="tooltip-marker twitter" />
          <p>Para iniciar: <strong>{projectHomeData?.productionStatus?.[role]?.absolute[0]}</strong></p>
        </div>
        <div className="tooltip-row">
          <div className="tooltip-marker tiktok" />
          <p>Em andamento: <strong>{projectHomeData?.productionStatus?.[role]?.absolute[1]}</strong></p>
        </div>
        <div className="tooltip-row">
          <div className="tooltip-marker youtube" />
          <p>Aguardando aprovação: <strong>{projectHomeData?.productionStatus?.[role]?.absolute[2]}</strong></p>
        </div>
        <div className="tooltip-row">
          <div className="tooltip-marker spotify" />
          <p>Finalizado: <strong>{projectHomeData?.productionStatus?.[role]?.absolute[3]}</strong></p>
        </div>
      </S.TooltipBase>
    )
  }

  const ProductionStatusBars = ({ role }) => {
    const arrSum = projectHomeData?.productionStatus?.[role]?.percentile?.reduce((initialVal, arrVal) => initialVal + arrVal);
    return (
      <>
        {arrSum > 0
          ?
          <div className='bar'>
            <div className='twitter' style={{
              width: `${projectHomeData?.productionStatus?.[role]?.percentile[0]}%`
            }}
            />
            <div className='tiktok' style={{
              width: `${projectHomeData?.productionStatus?.[role]?.percentile[1]}%`
            }}
            />
            <div className='youtube' style={{
              width: `${projectHomeData?.productionStatus?.[role]?.percentile[2]}%`
            }}
            />
            <div className='spotify' style={{
              width: `${projectHomeData?.productionStatus?.[role]?.percentile[3]}%`
            }}
            />
          </div>
          :
          <div className='bar'>
            <div className='emptyBar' style={{
              width: `100%`
            }}
            />
          </div>
        }
      </>
    )
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <VuiBox py={3}>
        <Grid container flexDirection='column' gap='16px'>
          <Grid container spacing="18px">
            <Grid item xs={12} lg={12} xl={3}>
              <WelcomeMark />
            </Grid>
            <Grid item xs={12} lg={6} xl={3}>
              <Notifications notifications={projectHomeData?.notifications} />
            </Grid>
            <Grid item xs={12} lg={6} xl={6}>
              <S.ScheduleList title="Agenda" maxHeight={"335px"} sx={{ paddingBottom: "0" }}>
                {projectHomeData?.calendar?.length === 0 && <p style={{ color: "#fff" }}>Sem informações</p>}
                {projectHomeData?.calendar?.length > 0 && projectHomeData?.calendar?.map((event, index) => (
                  <Grid key={index} onClick={() => { setEventToShow(event); setOpenEventDetails(true); }}>
                    <TimelineItem
                      color="viuzz"
                      icon={<IoEllipse />}
                      title={event?.title}
                      dateTimeStart={event?.start ? (moment(event?.start).format("D MMM HH:mm").toUpperCase()) : ''}
                      dateTimeEnd={event?.end ? (moment(event?.end).format("D MMM HH:mm").toUpperCase()) : ''}
                      badges={event?.extendedProps?.categories?.map(category => category.name)}
                      location={event?.extendedProps?.place}
                    />
                  </Grid>
                ))
                }
              </S.ScheduleList>
            </Grid>
          </Grid>
          {role === "H_MARKETING" &&
            <S.GraphMainContainer container spacing='18px'>
              <S.CardGrid item xs={12} lg={6} xl={7} sx={{ minHeight: "400px" }}>
                <Card>
                  <Grid container flexDirection='column'>
                    <Grid container>
                      <Grid item xs={12} md={12} xl={3}>
                        <p className='cardTitle'>Projetos</p>
                        <Grid container gap='2px'>
                          <p
                            className={
                              `percentage ${projectHomeData?.lastMonthProjects?.changeValues?.general?.up
                                ?
                                'positive'
                                :
                                'negative'}`
                            }
                          >
                            (
                            {projectHomeData?.lastMonthProjects?.changeValues?.general?.up ? '+' : ''}
                            {projectHomeData?.lastMonthProjects?.changeValues?.general?.value ?? 0}
                            )
                          </p>
                          <p className='subtitle'>no ultimo mês</p>
                        </Grid>
                      </Grid>
                      <S.BoardsGrid container item xs={12} md={12} xl={9} gap='10px'>
                        <S.SocialGrid className='instagram' item xl={2}>
                          <S.SocialNameGrid container justifyContent='center' gap='2px'>
                            <p>Em andamento</p>
                          </S.SocialNameGrid>
                          <Grid container justifyContent='center'>
                            <p>{projectHomeData?.lastMonthProjects?.changeValues?.ongoing?.value ?? 0}</p>
                            {projectHomeData?.lastMonthProjects?.changeValues?.ongoing?.up
                              ? <IoArrowUp color="#65EFAD" size="22px" />
                              : <IoArrowDown color="#D7007B" size="22px" />
                            }
                          </Grid>
                        </S.SocialGrid>
                        <S.SocialGrid className='tiktok' item xl={2}>
                          <S.SocialNameGrid container justifyContent='center' gap='2px'>
                            <p>Criados</p>
                          </S.SocialNameGrid>
                          <Grid container justifyContent='center'>
                            <p>{projectHomeData?.lastMonthProjects?.changeValues?.created?.value ?? 0}</p>
                            {projectHomeData?.lastMonthProjects?.changeValues?.created?.up
                              ? <IoArrowUp color="#65EFAD" size="22px" />
                              : <IoArrowDown color="#D7007B" size="22px" />
                            }
                          </Grid>
                        </S.SocialGrid>
                        <S.SocialGrid className='twitter' item xl={2}>
                          <S.SocialNameGrid container justifyContent='center' gap='2px'>
                            <p>Finalizados</p>
                          </S.SocialNameGrid>
                          <Grid container justifyContent='center'>
                            <p>{projectHomeData?.lastMonthProjects?.changeValues?.finished?.value ?? 0}</p>
                            {projectHomeData?.lastMonthProjects?.changeValues?.finished?.up
                              ? <IoArrowUp color="#65EFAD" size="22px" />
                              : <IoArrowDown color="#D7007B" size="22px" />
                            }
                          </Grid>
                        </S.SocialGrid>
                      </S.BoardsGrid>
                    </Grid>
                    <Grid sx={{ minHeight: "310px" }}>
                      <LineChart key={chartData} lineChartData={chartData} lineChartOptions={chartOptions} />
                    </Grid>
                  </Grid>
                </Card>
              </S.CardGrid>
              <Grid item xs={12} lg={6} xl={5} /* sx={{ minHeight: "400px" }} */>
                <Card>
                  <p>Status de produção</p>
                  <Grid container flexDirection='column' justifyContent='space-between' height='100%' mt='25px'>
                    <S.ProgressGrid
                      item
                      onMouseEnter={() => toggleShowTooltip(0, true)}
                      onMouseLeave={() => toggleShowTooltip(0, false)}
                    >
                      <Grid container justifyContent='space-between'>
                        <p className='followers'>Designers</p>
                      </Grid>
                      <S.BarContainer container>
                        <ProductionStatusBars role='DESIGNER' />
                        <ProductionStatusTooltip role='DESIGNER' />
                      </S.BarContainer>
                    </S.ProgressGrid>
                    <S.ProgressGrid
                      item
                      onMouseEnter={() => toggleShowTooltip(3, true)}
                      onMouseLeave={() => toggleShowTooltip(3, false)}
                    >
                      <Grid container justifyContent='space-between'>
                        <p className='followers'>Videomakers</p>
                      </Grid>
                      <S.BarContainer container>
                        <ProductionStatusBars role='FILMMAKER' />
                        <ProductionStatusTooltip role='FILMMAKER' />
                      </S.BarContainer>
                    </S.ProgressGrid>
                    <S.ProgressGrid
                      item
                      onMouseEnter={() => toggleShowTooltip(2, true)}
                      onMouseLeave={() => toggleShowTooltip(2, false)}
                    >
                      <Grid container justifyContent='space-between'>
                        <p className='followers'>Social Media</p>
                      </Grid>
                      <S.BarContainer container>
                        <ProductionStatusBars role='SOCIAL_MEDIA' />
                        <ProductionStatusTooltip role='SOCIAL_MEDIA' />
                      </S.BarContainer>
                    </S.ProgressGrid>
                    <S.ProgressGrid
                      item
                      onMouseEnter={() => toggleShowTooltip(1, true)}
                      onMouseLeave={() => toggleShowTooltip(1, false)}
                    >
                      <Grid container justifyContent='space-between'>
                        <p className='followers'>Produção</p>
                      </Grid>
                      <S.BarContainer container>
                        <ProductionStatusBars role='PRODUCER' />
                        <ProductionStatusTooltip role='PRODUCER' />
                      </S.BarContainer>
                    </S.ProgressGrid>
                  </Grid>
                </Card>
              </Grid>
            </S.GraphMainContainer>
          }
          <Grid container spacing="18px">
            <Grid item sm={12} xl={12}>
              <LastProjects
                projects={projectHomeData?.lastProjectsCreated ? projectHomeData?.lastProjectsCreated?.slice(0, 5) : []}
                isMarketing={role === 'MARKETING'}
              />
            </Grid>
          </Grid>
        </Grid>
        <Modal open={openEventDetails} onClose={() => { setOpenEventDetails(false); setEventToShow(); }}>
          <EventDetails event={eventToShow} />
        </Modal>
      </VuiBox>
    </DashboardLayout>
  )
}

export default MarketingDashboard